<template>
    <div>
        <!-- # 헤더 -->
        <v-sheet
            style="border-bottom:1px solid #eee;"
        >
            <v-sheet
                height="64"
                class="px-3 d-flex justify-space-between align-center"
            >
                <!-- # 사이드메뉴 -->
                <v-btn
                    icon
                    @click="drawer = !drawer"
                >
                    <v-icon>mdi-menu</v-icon>
                </v-btn>

                <!-- 로고 -->
                <v-sheet 
                    width="44"
                    style="cursor:pointer;"
                    @click="$router.push('/')"
                >
                    <div>
                        <v-img
                            width="44"
                            height="44"
                            contain
                            src="@/assets//logo/icon.svg"
                        ></v-img>
                    </div>
                </v-sheet>

                <!-- # 로그인 전 -->
                <v-btn
                    v-if="!$store.state.page_user.is_logined"
                    icon
                    @click="$router.push('/auth/login')"
                >
                    <v-icon>mdi-account-circle-outline</v-icon>
                </v-btn>

                <!-- # 로그인 후 -->
                <v-btn
                    v-else
                    icon
                    color="primary"
                    @click="$router.push('/mypage/dashboard')"
                >
                    <v-icon>mdi-cog-outline</v-icon>
                </v-btn>
            </v-sheet>
        </v-sheet>

        <!-- # 사이드메뉴 -->
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
        >
            <!-- 로고 -->
            <v-sheet 
                class="d-flex align-center pa-4"
                width="120"
                style="cursor:pointer;"
                @click="link('/')"
            >
                <div>
                    <v-img
                        width="40"
                        height="40"
                        contain
                        src="@/assets//logo/icon.svg"
                    ></v-img>
                </div>
                <p
                    class="ml-2 mb-0 logo_font"
                >
                    page
                </p>
            </v-sheet>

            <v-divider class="mb-2"></v-divider>

            <v-list
                nav
                class="font-weight-medium px-2"
            >
                <v-list-item
                    v-for="(item, index) in menu" :key="index"
                    class="px-4"
                    active-class="primary white--text"
                    :to="item.url"
                    exact
                >
                    {{item.text}}
                    <v-icon size="16" color="primary lighten-2" class="ml-2 mt-2px">{{item.icon}}</v-icon>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
export default {
    data: () => ({
        drawer: false,

        menu: [
            {
                icon: "mdi-book-open-page-variant-outline",
                text: "오늘의 책 알림",
                url: "/service/book"
            },
            {
                icon: "mdi-layers-search-outline",
                text: "책 요약 검색",
                url: "/service/search"
            },
            {
                icon: "mdi-post-outline",
                text: "오늘의 글 알림", 
                url: "/service/text"
            },
            {
                icon: "mdi-chat",
                text: "북 큐레이션 챗봇",
                url: "/service/chat"
            },
            {
                icon: "mdi-slash-forward-box",
                text: "책 1/7 알림", 
                url: "/service/divide"
            },
            {
                icon: "mdi-list-box-outline",
                text: "나의 책 후기", 
                url: "/post/list?type=my"
            },
            {
                icon: "mdi-list-box-outline",
                text: "모두의 책 후기",
                url: "/post/list?type=review"
            },
            {
                icon: "mdi-list-box-outline",
                text: "자유게시판", 
                url: "/post/list?type=free"
            }
        ]
    }),

    methods: {

    }
}
</script>