<template>
    <v-sheet
        :class="{
            'pa-5 pt-14 pb-12': !$vuetify.breakpoint.mobile,
            'pt-14 pb-16': $vuetify.breakpoint.mobile
        }"
        color="#f8faff"
    >
        <!-- # 제목 -->
        <p
            class="banner_font text-center mb-0"
            :style="{ fontSize: !$vuetify.breakpoint.mobile ? '28px' : '22px' }"
            data-aos="fade-up"
            data-aos-duration="2000"
        >
            매일 새로운 책을 발견하다
        </p>

        <!-- # 이미지 -->
        <v-img
            class="mx-auto"
            :width="!$vuetify.breakpoint.mobile ? '240' : '50%'"
            contain
            data-aos="fade-up"
            data-aos-duration="2000"
            src="@/assets/home/section1.png"
        ></v-img>

        <!-- # 카테고리 -->
        <v-select
            :style="{ width: !$vuetify.breakpoint.mobile ? '300px' : '240px', background: 'white' }"
            class="shrink mx-auto rounded-10 mt-4"
            label="카테고리"
            outlined 
            hide-details
            item-text="name"
            item-value="value"
            :items="select_list.category"
            v-model="options.category"
            multiple
        ></v-select>

        <!-- # 시간 -->
        <v-text-field
            :style="{ width: !$vuetify.breakpoint.mobile ? '300px' : '240px', background: 'white' }"
            class="shrink mx-auto rounded-10 mt-4"
            label="시간"
            outlined
            hide-details
            v-model="options.time"
            type="time"
        ></v-text-field>

        <!-- # 알림방식 -->
        <v-select
            :style="{ width: !$vuetify.breakpoint.mobile ? '300px' : '240px', background: 'white' }"
            class="shrink mx-auto rounded-10 mt-4"
            label="알림방식"
            outlined
            hide-details
            :items="select_list.send_type"
            v-model="options.send_type"
        ></v-select>

        <!-- # 알림 설정 -->
        <v-btn
            width="200"
            class="d-flex mx-auto mt-8 rounded-10"
            large
            depressed
            :disabled="!$store.state.page_user.is_logined"
            :dark="$store.state.page_user.is_logined"
            :style="$store.state.page_user.is_logined? '':'color: #999'"
            :color="$store.state.page_user.is_logined? '#079aaa':'#ddd'"
            @click="submit()"
        >
            <span v-if="$store.state.page_user.is_logined">오늘의 책 알림 설정하기</span>
            <span v-else>로그인 이후 이용 가능</span>
        </v-btn>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        // # 선택 목록
        select_list: {
            category: [
                { value: "랜덤", name: "랜덤하게 책을 추천해줘" },
                { value: "자기계발", name: "자기계발 관련 책을 추천해줘" },
                { value: "여행", name: "여행 관련 책을 추천해줘" },
                { value: "건강", name: "건강 관련 책을 추천해줘" },
                { value: "요리", name: "요리 관련 책을 추천해줘" },
                { value: "힐링", name: "힐링 관련 책을 추천해줘" },
                { value: "역사", name: "역사 관련 책을 추천해줘" },
                { value: "예술", name: "예술 관련 책을 추천해줘" },
                { value: "종교", name: "종교 관련 책을 추천해줘" },
                { value: "철학", name: "철학 관련 책을 추천해줘" },
                { value: "심리학", name: "심리학 관련 책을 추천해줘" },
                { value: "의학", name: "의학 관련 책을 추천해줘" },
                { value: "경제", name: "경제 관련 책을 추천해줘" },
                { value: "육아", name: "육아 관련 책을 추천해줘" },
            ],

            send_type: [
                "카톡",
                "이메일",
            ]
        },

        // # 알람 설정
        options: {
            category: ["랜덤"],
            time: null,
            send_type: "카톡"
        },

        // token: "",
    }),

    async mounted() {
        // // 토큰 요청
        // const response = await this.$http.post('https://www.biztalk-api.com/v2/auth/getToken',
        //     {
        //         bsid: 'sharit',
        //         passwd: '29c0413c78c812c895ed376126301a71345e479d'
        //     },
        //     {
        //         headers: {
        //             'Content-Type': 'application/json'
        //         }
        //     }
        // )

        // this.token = response.data.token
        // console.log(response)
        // console.log('Token:', this.token)

        const now = new Date()
        now.setMinutes(now.getMinutes() + 2) // 현재 시간에 2분 추가
        const hours = String(now.getHours()).padStart(2, '0')
        const minutes = String(now.getMinutes()).padStart(2, '0')
        this.options.time = `${hours}:${minutes}`
    },

    methods: {
        // # 알림 설정
        async submit() {
            await new Promise(resolve => setTimeout(resolve, 1000)) // 1초 대기

            // # 랜덤 처리 (중복)
            let selectedCategories = this.options.category
            if (selectedCategories.includes("랜덤")) {
                selectedCategories = ["랜덤"]
            } else {
                const randomIndex = Math.floor(Math.random() * selectedCategories.length)
                selectedCategories = [selectedCategories[randomIndex]]
            }

            const selectedCategory = selectedCategories[0]

            // # 랜덤 처리 (중복 이전)
            // let random_category = this.category
            // if(random_category == "랜덤") {
            //     const nonRandomCategories = this.select_list.category.filter(cat => cat.value != "랜덤")
            //     const randomIndex = Math.floor(Math.random() * nonRandomCategories.length)
            //     random_category = nonRandomCategories[randomIndex].value
            // }

            // # API 처리
            if(this.options.send_type == "이메일"){
                this.$http.post("/api/service/book/email", {
                    params: {
                        title: selectedCategory,
                        time: this.options.time,
                        user_id: this.$store.state.page_user.user_id,
                    }
                }).then((res) => {
                    console.log(res)
                })
            }
            else if(this.options.send_type == "카톡"){
                this.$http.post("/api/service/book/kakao", {
                    params: {
                        title: selectedCategory,
                        time: this.options.time,
                        user_id: this.$store.state.page_user.user_id,
                        phone: this.$store.state.page_user.phone,
                        // token: this.token
                    }
                }).then((res) => {
                    console.log(res)
                })
            }

            // # 확인창
            alert("책 알림이 설정되었습니다.")
        },
    }
}
</script>