<template>
    <v-sheet class="d-flex">
        <v-sheet class="pr-7 pt-4">
            <v-icon size="20" color="blue lighten-2">mdi-arrow-right-bottom</v-icon>
        </v-sheet>
        <!-- 댓글 목록 -->
        <v-sheet width="100%">
            <!-- 목록 -->
            <v-sheet
                v-for="item in comment_list" :key="item.id"
                class="pt-3 pb-4 my-1"
                style="border-top:1px solid #eee;"
            >
                <!-- 상단 -->
                <v-sheet
                    class="d-flex align-center mb-2"
                >
                    <!-- 프로필 -->
                    <Profile
                        :user_id="item.user_id"
                    />

                    <!-- 작성자일 시 -->
                    <v-icon
                        v-if="item.user_id == writer"
                        size="18"
                        class="ml-1 mb-6px"
                        color="blue"
                    >
                        mdi-check-circle
                    </v-icon>

                    <!-- 댓글 작성일 -->
                    <font
                        v-if="!$vuetify.breakpoint.xs"
                        class="ml-3 mr-2 mb-6px"
                        style="font-size:13px; color:#ADAFCA; line-height:18px;"
                    >
                        {{new Date(item.created).toLocaleString()}}
                    </font>

                    <!-- 댓글 삭제하기 -->
                    <v-sheet
                        v-if="item.user_id == $store.state.page_user.user_id"
                        title="댓글 삭제하기"
                        width="19"
                        height="19"
                        class="mx-1 mb-6px"
                        style="cursor:pointer;"
                        @click="delete_subcomment(item)"
                    >
                        <v-img contain src="@/assets/board/delete.svg"></v-img>
                    </v-sheet>

                    <!-- 댓글 수정하기 -->
                    <v-sheet
                        v-if="item.user_id == $store.state.page_user.user_id"
                        title="댓글 수정하기"
                        width="19"
                        height="19"
                        class="mx-1 mb-6px"
                        style="cursor:pointer;"
                        @click="item.isUpdating = !item.isUpdating"
                    >
                        <v-img contain src="@/assets/board/update.svg"></v-img>
                    </v-sheet>

                    <v-spacer></v-spacer>

                    <!-- 좋아요 -->
                    <v-btn
                        class="mr-2 px-2"
                        v-ripple="false"
                        text
                        @click="subcomment_like(item)"
                    >
                        <v-img width="20" src="@/assets/board/like_default.png"></v-img>
                        <span class="text-caption font-weight-medium ml-2 mb-1">
                            좋아요 {{item.like_count}}
                        </span>
                    </v-btn>
                </v-sheet>

                <!-- 댓글 내용 -->
                <div
                    v-if="!item.isUpdating"
                    class="pa-3"
                    style="white-space:pre-line; font-size:13px;"
                    v-html="$linkify(item.content)"
                ></div>

                <!-- 수정 시 -->
                <div class="mt-4">
                    <!-- 내용 -->
                    <v-sheet v-if="item.isUpdating" outlined class="d-flex justify-center py-1 mr-2">
                        <v-textarea
                            hide-details
                            flat
                            solo
                            v-model="item.content"
                            style="font-size:13px;"
                            placeholder="평가 내용을 입력해주세요"
                        ></v-textarea>
                    </v-sheet>

                    <!-- 수정, 취소 버튼 -->
                    <div v-if="item.isUpdating" class="mt-2 mb-4 d-flex justify-end">
                        <v-btn
                            width="90"
                            height="35"
                            class="mr-2"
                            color="grey"
                            outlined
                            @click="subcomment_update_submit(item)"
                        >
                            수정
                        </v-btn>
                        <v-btn
                            width="90"
                            height="35"
                            class="mr-2"
                            color="grey"
                            outlined
                            @click="item.isUpdating = false; load()"
                        >
                            취소
                        </v-btn>
                    </div>
                </div>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
import Profile from "../profile"

export default {
    components: {
        Profile
    },

    props: [
        "comment_id",
        "board_name",
        "title",
        "writer"
    ],

    data: () => ({
        comment_list: [],

        reportComment: {
            reported_content: "",
            id: 0,
            writer: "",
            category: "",
            content: ""
        },

        select_list: {
            report: [
                "욕설/비방",
                "도배",
                "홍보/사업성",
                "음란성",
                "분란조장",
                "시세조작",
                "기타"
            ]
        },

        dialog: {
            reportComment: false
        },
    }),

    mounted(){
        // 대댓글 목록
        this.load()
    },

    methods: {
        // 대댓글 목록
        load(){
            this.$http.post('/api/post/subcomment/list', {
                params: {
                    comment_id: this.comment_id
                }
            }).then((res) => {
                this.comment_list = res.data
                this.$emit("updated")
            })
        },

        // 대댓글 삭제
        delete_subcomment(item){
            if(confirm("정말 해당 댓글을 삭제하시겠습니까?")){
                this.$http.post('/api/post/subcomment/delete', {
                    params: {
                        id: item.id
                    }
                }).then(() => {
                    alert('댓글이 삭제되었습니다.')

                    // 댓글 목록
                    this.load()
                })
            }
        },

        // 대댓글 수정
        subcomment_update_submit(item){
            this.$http.post('/api/post/subcomment/update', {
                params: {
                    id :item.id,
                    content :item.content
                }
            }).then(() => {
                // 댓글 목록
                this.load()
            })
        },

        // 댓글 추천
        subcomment_like(item){
            if(!this.$store.state.page_user.is_logined){
                alert("댓글 추천은 로그인 이후 가능합니다.")
            }else if(this.$store.state.page_user.user_id == item.user_id){
                alert('자신의 댓글에 추천을 할 수 없습니다.')
            }else{
                this.$http.post('/api/post/subcomment/like/list', {
                    params: {
                        sub_comment_id: item.id,
                        user_id: this.$store.state.page_user.user_id
                    }
                }).then((res) => {
                    if(res.data.length){
                        alert("이미 추천한 댓글입니다.")
                    }else{
                        this.$http.post('/api/post/subcomment/like', {
                            params: {
                                sub_comment_id: item.id,
                                user_id: this.$store.state.page_user.user_id
                            }
                        }).then((res) => {
                            // 댓글 목록
                            this.load()
                        })
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>
<style scoped>
.v-btn::before {
    background-color: transparent;
}
</style>