<template>
    <div>
        <!-- # PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- # 섹션 2 -->
            <v-sheet
                class="pa-5 pt-4 pb-16 mb-10"
                color="#fffdef"
            >
                <div
                    class="d-flex mx-auto justify-center"
                    style="width:1200px; padding:40px 160px; padding-bottom:20px;"
                >
                    <div>
                        <v-img
                            class="d-flex justify-center mx-auto"
                            width="240"
                            height="220"
                            contain
                            src="@/assets/chat.svg"
                        ></v-img>
                        
                        <p
                            class="banner_font text-center mb-0"
                            style="line-height:54px; font-size:50px;"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                        >
                            북 큐레이션 챗봇
                        </p>

                        <p
                            class="banner_font text-center mb-0"
                            style="line-height:60px; font-size:32px;"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                        >
                            어떤 책이 읽고 싶으신지 상담해드릴게요
                        </p>
                    </div>
                </div>

                <v-sheet
                    outlined
                    class="pt-0 pb-16 px-16 mx-auto rounded-15"
                    width="800"
                    color="transparent"
                >
                    <!-- 답변 전 -->
                    <div
                        v-if="!answered && !loading"
                    >
                        <v-btn
                            class="rounded-10 d-flex mx-auto mt-6 mb-4"
                            color="#01ada4"
                            width="200"
                            dark
                            depressed
                            @click="callOpenAI()"
                        >
                            챗봇과 상담하기
                        </v-btn>
                    </div>

                    <!-- 답변 후 -->
                    <div
                        v-if="answered && !loading"
                        class="text-body-1 mt-8 mx-auto"
                    >
                        <v-img
                            style="width:160px; margin:0 auto; box-shadow:0px 0px 4px 4px #eee; border-radius:5px;"
                            :src="book_image"
                        ></v-img>
                    </div>

                    <!-- 답변 후 -->
                    <div
                        v-if="answered && !loading"
                        class="text-body-1 mx-auto pr-2 mt-12"
                        style="width:400px; white-space: pre-line;"
                    >
                        <!-- {{response}} -->
                        <div v-html="formattedResponse"></div>
                    </div>

                    <v-btn
                        v-if="answered && !loading"
                        class="rounded-10 d-flex mx-auto mt-8 mb-8"
                        color="#01ada4"
                        width="200"
                        dark
                        depressed
                        @click="reset()"
                    >
                        다시하기
                    </v-btn>

                    <!-- 로딩 -->
                    <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary"
                        class="d-flex mt-14 mb-12 mx-auto"
                        size="60"
                    ></v-progress-circular>
                </v-sheet>
            </v-sheet>
        </div>

        <!-- # Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- # 섹션 2 -->
            <v-sheet
                class="pa-5 pt-4 pb-80px"
                color="#fffdef"
            >
                <div>
                    <v-img
                        class="d-flex justify-center mx-auto"
                        width="70%"
                        contain
                        src="@/assets/home/section2.png"
                    ></v-img>
                    
                    <p
                        class="banner_font text-center mb-0 mt-6"
                        style="font-size:26px;"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                    >
                        책 이름을 알려주세요
                    </p>

                    <p
                        class="banner_font text-center mb-0"
                        style="font-size:18px;"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                    >
                        읽기 전에 요약해드릴게요
                    </p>
                </div>

                <v-sheet
                    outlined
                    class="pt-0 pb-16 mx-auto rounded-15"
                    color="transparent"
                >
                    <!-- 답변 전 -->
                    <div
                        v-if="!answered && !loading"
                    >
                        <!-- 입력 -->
                        <v-text-field
                            style="width:240px; background:white;"
                            class="rounded-10 mx-auto mt-6 shrink"
                            v-model="name"
                            outlined
                            dense
                            hide-details
                            placeholder="책 이름"
                        ></v-text-field>

                        <v-btn
                            class="rounded-10 d-flex mx-auto mt-8 mb-4"
                            color="#01ada4"
                            width="200"
                            dark
                            large
                            depressed
                            @click="callOpenAI()"
                        >
                            책 요약 받기
                        </v-btn>
                    </div>

                    <!-- 답변 후 -->
                    <div
                        v-if="answered && !loading"
                        class="text-body-1 mt-8 mx-auto"
                    >
                        <v-img
                            style="width:120px; margin:0 auto; box-shadow:0px 0px 4px 4px #eee; border-radius:5px;"
                            :src="book_image"
                        ></v-img>
                    </div>

                    <!-- 답변 후 -->
                    <div
                        v-if="answered && !loading"
                        class="text-caption mx-auto mt-6 pl-2"
                        style="width:100%; white-space: pre-line;"
                    >
                        <div v-html="formattedResponse"></div>
                    </div>

                    <v-btn
                        v-if="answered && !loading"
                        class="rounded-10 d-flex mx-auto mt-8 mb-8"
                        color="#01ada4"
                        width="200"
                        dark
                        depressed
                        @click="reset()"
                    >
                        다시하기
                    </v-btn>

                    <!-- 로딩 -->
                    <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary"
                        class="d-flex mt-14 mb-12 mx-auto"
                        size="60"
                    ></v-progress-circular>
                </v-sheet>
            </v-sheet>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        name: "",
        apiKey: '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',
        response: '',
        answered: false,
        loading: false,

        book_image: ""
    }),

    computed: {
        formattedResponse() {
            return this.response.replace(/^(\d+\.\s+[^:]+:)/gm, '<div class="font-weight-bold text-subtitle-1" style="margin-bottom:-12px;">$1</div>')
        }
    },

    methods: {
        reset(){
            this.name = ""
            this.answered = ""
        },

        // AI 카운팅 불러오기
        ai_counting(){
            this.$http.post('/api/simple/ai_count/update', {
                params: {
                    ai_category: "chat"
                }
            }).then((res) => {
                console.log(res)
            })
        },

        async callOpenAI() {
            this.ai_counting()

            alert("북 큐레이션 챗봇은 준비중입니다")

            return 

            if(this.name == ""){
                alert("입력란이 비어있습니다")
                return
            }

            this.loading = true

            const bookInfoResult = await this.$http.post("/api/service/search", {
                params: {
                    title: this.name
                }
            })

            const bookInfo = bookInfoResult.data.documents[0]


            console.log(bookInfo)

            this.book_image = bookInfo.thumbnail

            // ChatGPT 프롬프트 생성
            this.prompt = `'${this.name}'이란 책에 대한 정보입니다:
            - 제목: ${bookInfo.title}
            - 저자: ${bookInfo.authors.join(', ')}
            - 출판사: ${bookInfo.publisher}
            - 출판일: ${bookInfo.datetime}
            - 설명: ${bookInfo.contents}

            위 정보를 참고하여 이 책의 내용을 분석하고 최대한 교훈적으로 요약해주세요.
            
            책 제목을 적은 다음
            그리고 내용은 다음과 같이 5개의 문단으로 설명해주세요:
            1. 핵심 메세지
            2. 저자 소개
            3. 출판일
            4. 주요 주제
            5. 주요 인사이트
            
            주요 주제는 목록 형태로 6 ~ 10개의 주제 형태로 적어줘
            주요 인사이트는 목록 형태로 6 ~ 10개의 인사이트 형태로 적어줘
            `

            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: this.prompt }],
                    temperature: 0,
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.loading = false
                this.response = result.data.choices[0].message.content
                // .replace(/\n/g, '\n\n')
                console.log(this.response)
                this.answered = true
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }
        },
    }
}
</script>