<template>
    <v-sheet
        v-if="user"
        class="mb-2 mr-1 d-flex align-center justify-end"
        style="cursor:pointer;"
    >
        <!-- 프로필 이미지 -->
        <v-sheet>
            <v-img
                v-if="user.profile_image"
                class="rounded-circle mr-2"
                style="width:24px; height:24px;"
                :src="'/upload/user/profile_image/' + user.profile_image"
            ></v-img>
        </v-sheet>

        <!-- 닉네임 -->
        <font class="font-weight-bold text-subtitle-2">
            {{user.nickname}}
        </font>
    </v-sheet>
</template>
<script>
export default {
    props: ["user_id"],

    data: () => ({
        user: {
            profile_image: "",
            nickname: ""
        }
    }),

    mounted(){
        this.$http.post("/api/post/select/profile", {
            params: {
                user_id: this.user_id
            }
        }).then((res) => {
            this.user = res.data[0]
        })
    }

}
</script>