<template>
    <div
        :style="$vuetify.breakpoint.mobile? '':'width:1200px; margin:0 auto;'"
        class="py-4 mb-10"
    >
        <p
            class="font-weight-medium text-center"
            :class="$vuetify.breakpoint.mobile? 'text-h6 mb-2':'text-h5'"
        >
            {{this.$route.query.type == "my" ? "나의 책 후기" : this.$route.query.type == "review" ? "모두의 책 후기" : "자유게시판"}}
        </p>

        <v-divider
            :class="$vuetify.breakpoint.mobile? 'mt-2 mb-4':'mt-4 mb-6'"
            :style="$vuetify.breakpoint.mobile? 'width:200px; margin:0 auto;':'width:400px; margin:0 auto;'"
        ></v-divider>

        <v-row
            v-if="list.length && $route.query.type != 'free'"
            :class="$vuetify.breakpoint.mobile? 'px-4':''"
        >
            <v-col
                v-for="item in list" :key="item.id"
                :cols="$vuetify.breakpoint.mobile? 12:3"
                :class="$vuetify.breakpoint.mobile? 'px-2':'px-4'"
            >
                <v-card
                    :class="$vuetify.breakpoint.mobile? '':'mb-10'"
                    class="rounded-10"
                    style="cursor:pointer; box-shadow: 0px 4px 16px #ddd;"
                    @click="read(item)"
                >
                    <!-- 썸네일 -->
                    <v-img
                        height="200"
                        style="border-radius: 5px 5px 0 0;"
                        :src="item.image? item.image : require('@/assets/board/thumbnail.png')"
                    ></v-img>

                    <!-- 텍스트 -->
                    <v-sheet class="pt-4 pl-5 pr-6 pb-2 rounded-10">
                        <div class="d-flex justify-space-between">
                            <v-sheet width="240">
                                <!-- 제목 -->
                                <p class="text-subtitle-1 font-weight-medium mb-0 text-truncate pr-2" style="line-height:22px;">
                                    {{item.title}}
                                </p>
                            </v-sheet>
                        </div>

                        <!-- 닉네임 & 조회수 & 좋아요 & 댓글 -->
                        <v-sheet width="100%" class="d-flex align-center justify-end">
                            <!-- 닉네임 & 조회수 -->
                            <span class="text-caption">
                                {{item.nickname}}ㆍ조회수 {{item.view_count}}
                            </span>
                            <v-spacer></v-spacer>

                            <!-- 좋아요 -->
                            <v-sheet>
                                <v-img
                                    class="mr-2 mt-1px"
                                    width="17"
                                    src="@/assets/board/like_default.png"
                                ></v-img>
                            </v-sheet>
                            <span class="text-body-1 mb-1px font-weight-medium">
                                {{item.like_count}}
                            </span>

                            <!-- 댓글 -->
                            <v-sheet>
                                <v-img
                                    class="ml-4 mr-2"
                                    width="14"
                                    src="@/assets/board/comment.png"
                                ></v-img>
                            </v-sheet>
                            <span class="text-body-1 mb-1px font-weight-medium">
                                {{item.comment_count}}
                            </span>
                        </v-sheet>
                    </v-sheet>
                </v-card>
            </v-col>
        </v-row>

        <div
            v-if="list.length && $route.query.type == 'free'"
            class="px-2"
        >
            <v-row
                :class="$vuetify.breakpoint.mobile? '':''"
                :style="$vuetify.breakpoint.mobile? '':'width:1000px; margin:0 auto;'"
                style="box-shadow: 0px 3px 12px #ddd; overflow:hidden;"
                class="rounded-10"
                no-gutters
            >
                <v-col
                    v-for="item in list" :key="item.id"
                    :cols="$vuetify.breakpoint.mobile? 12:12"
                    :class="$vuetify.breakpoint.mobile? 'px-3':'px-6'"
                    style="border-bottom:1px solid #ddd;"
                >
                    <v-sheet
                        :class="$vuetify.breakpoint.mobile? '':'mb-2'"
                        class="rounded-10"
                        style="cursor:pointer;"
                        @click="read(item)"
                    >
                        <!-- 텍스트 -->
                        <v-sheet class="pa-2 rounded-10">
                            <!-- 제목 -->
                            <p 
                                class="font-weight-medium mb-0 text-truncate pr-2 mt-0 mb-0"
                                :style="$vuetify.breakpoint.mobile? 'font-size:18px; line-height:22px;':'font-size:22px;'"
                            >
                                {{item.title}}
                            </p>

                            <!-- 닉네임 & 조회수 & 좋아요 & 댓글 -->
                            <v-sheet width="100%" class="d-flex align-center justify-end">
                                <!-- 닉네임 & 조회수 -->
                                <span class="text-caption">
                                    {{item.nickname}}ㆍ조회수 {{item.view_count}}ㆍ<span class="grey--text text--darken-1">{{new Date(item.created).toLocaleDateString()}}</span>
                                </span>
                                <v-spacer></v-spacer>

                                <!-- 좋아요 -->
                                <v-sheet>
                                    <v-img
                                        class="mr-2 mt-1px"
                                        width="17"
                                        src="@/assets/board/like_default.png"
                                    ></v-img>
                                </v-sheet>
                                <span class="text-body-1 mb-1px font-weight-medium">
                                    {{item.like_count}}
                                </span>

                                <!-- 댓글 -->
                                <v-sheet>
                                    <v-img
                                        class="ml-4 mr-2"
                                        width="14"
                                        src="@/assets/board/comment.png"
                                    ></v-img>
                                </v-sheet>
                                <span class="text-body-1 mb-1px font-weight-medium">
                                    {{item.comment_count}}
                                </span>
                            </v-sheet>
                        </v-sheet>
                    </v-sheet>
                </v-col>
            </v-row>
        </div>

        <div
            v-if="$route.query.type != 'free'"
            :class="$vuetify.breakpoint.mobile? 'mt-6':''"
            class="d-flex justify-end px-1"
        >
            <v-btn
                @click="write()"
                color="primary"
                class="rounded-10 px-8"
                large
                depressed
            >
                작성하기
            </v-btn>
        </div>

        <div
            v-if="$route.query.type == 'free'"
            :class="$vuetify.breakpoint.mobile? 'mt-4':'mt-4'"
            class="d-flex justify-end px-2"
            :style="$vuetify.breakpoint.mobile? '':'width:1000px; margin:0 auto;'"
        >
            <v-btn
                @click="write()"
                color="primary"
                class="rounded-10 px-8"
                large
                depressed
            >
                작성하기
            </v-btn>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        list: []
    }),

    mounted() {
        // 책 리뷰 불러오기
        this.load()
    },

    methods: {
        // 책 리뷰 불러오기
        load() {
            this.$http.post("/api/post/select", {
                params: {
                    type: this.$route.query.type,
                    user_id: this.$store.state.page_user.user_id
                }
            }).then(res => {
                console.log(res)
                this.list = res.data

                // 썸네일 추가
                this.matchThumbnail(this.list)
            })
        },

        // 썸네일 추가
        matchThumbnail(list){
            // 이미지
            const imgReg = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i

            list.forEach(e => {
                if (typeof e.content === 'string' && e.content.match(imgReg)) {
                    e.image = e.content.match(imgReg)[0].replace(/.*src="([^"]*)".*/, '$1');
                }
            })
        },

        // 책 리뷰 작성하기
        write() {
            if(this.$store.state.page_user.is_logined) {
                this.$router.push("/post/write?type=" + this.$route.query.type)
            } else {
                this.$router.push("/auth/login")
            }
        },

        // 읽기로 이동
        read(item){
            if(!this.$store.state.page_user.is_logined){
                alert("게시글 읽기는 로그인 후 이용가능합니다.")
            }else{
                this.$router.push("/post/read?id=" + item.id + "&type=" + this.$route.query.type)
            }
        },
    }
}
</script>