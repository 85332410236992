<template>
    <div>
        <!-- 본문 -->
        <div
            :class="$vuetify.breakpoint.mobile? 'py-6 mb-4':'py-16 mb-16'"
        >
            <v-sheet
                class="py-16 px-8 mx-auto rounded-lg text-center"
                :width="$vuetify.breakpoint.mobile? '94%':480"
                outlined
            >
                <!-- 로고 & 문구 -->
                <div
                    class="d-flex mb-2 mx-auto"
                >
                    <v-img
                        :height="$vuetify.breakpoint.mobile? 80:120"
                        :width="$vuetify.breakpoint.mobile? 80:120"
                        contain
                        src="@/assets/logo/icon.svg"
                    ></v-img>
                </div>
                <p
                    class="mb-6 text-subtitle-1 font-weight-medium grey--text text--darken-2"
                >
                    아이디 찾기
                </p>

                <v-sheet
                    :width="$vuetify.breakpoint.mobile? '94%':280"
                    class="mx-auto"
                >
                    <!-- 입력 -->
                    <v-text-field
                        outlined
                        flat
                        dense
                        v-model="nickname"
                        autofocus
                        hide-details
                        placeholder="닉네임"
                        @keyup.enter="submit()"
                    >
                    </v-text-field>
                    <p class="mt-2 mb-8 text-caption text-center">
                        닉네임을 입력해주시면<br/>
                        가입하신 아이디(이메일)을 알려드리겠습니다
                    </p>

                    <!-- 발송 -->
                    <v-btn
                        class="font-weight-bold"
                        color="primary"
                        dark
                        block
                        large
                        depressed
                        @click="submit()"
                    >
                        아이디 찾기
                    </v-btn>
                </v-sheet>

                <!-- 버튼 -->
                <div class="d-flex justify-center mt-4">
                    <v-btn
                        class="mr-1"
                        color="grey darken-1"
                        text
                        small
                        to="/auth/login"
                    >
                        로그인으로 돌아가기
                    </v-btn>
                    <v-btn
                        color="grey darken-1"
                        text
                        small
                        to="/auth/find/password"
                    >
                        비밀번호 찾기
                    </v-btn>
                </div>
            </v-sheet>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        nickname: ""
    }),

    methods: {
        submit(){
            // 이메일로 회원 찾기
            this.$http.post('/api/user/select/specific/by_nickname', {
                params: {
                    nickname: this.nickname
                }
            }).then((res) => {
                if(!res.data.length){
                    alert("해당 이메일로 가입된 회원이 없습니다.")
                }else{
                    alert("가입하신 아이디(이메일)는 '" + res.data[0].user_id + "'입니다")
                }
            })
        }
    }
}
</script>