<template>
    <v-sheet
        style="padding:100px 100px 120px 100px;"
    >

        <v-btn
            @click="requestToken()"
        >
            토큰 요청
        </v-btn>

        <v-btn
            @click="sendAlimTalk()"
        >
            발송 요청
        </v-btn>

        <v-btn
            @click="getResultAll()"
        >
            결과 확인
        </v-btn>

        {{token}}
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        token: '',
        msgIdx: '115',
        countryCode: '82',
        resMethod: 'PUSH',
        senderKey: 'f1faca8aa016dfdba6537f5adec41a4336e57ceb',
        tmpltCode: 'page_book',
        message: `
1. 핵심 메세지:
- 우리는 누구나 옳고 소중한 존재이며, 자기 자신을 인정하고 사랑하는 것이 중요하다.

2. 저자 소개:
- 정혜신은 30년간 정신과 의사로 활동하며 많은 사람들의 속마음을 듣고 나누었고, 다양한 책을 통해 독자들과 소통해왔다.

3. 출판일:
- 2018년 10월 10일에 출판되었다.

4. 주요 주제:
- 자기 자신을 인정하고 사랑하는 법
- 심리적 CPR의 행동지침
- 국가폭력 피해자와 상처받은 이들의 치유와 회복
- 사회적 상황에서의 정신적 지원 방법

5. 주요 인사이트:
- 모든 사람은 소중하고 옳은 존재이다.
- 자기 자신을 인정하고 사랑하는 것이 가장 중요하다.
- 상처받은 이들을 도와주는 것은 우리 모두의 책임이다.
- 심리적 CPR은 누구나 배울 수 있는 행동이다.
- 사회적 상황에서의 정신적 지원은 중요하다.
- 상처받은 이들을 위로하고 치유하는 것은 우리의 의무이다.
        `,
        recipient: '01063444580'
        // recipient: '01027317679'
    }),

    methods: {
        async requestToken() {
            try {
                const response = await this.$http.post('https://www.biztalk-api.com/v2/auth/getToken',
                    {
                        bsid: 'sharit',
                        passwd: '29c0413c78c812c895ed376126301a71345e479d'
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )

                this.token = response.data.token
                console.log(response)
                console.log('Token:', this.token)
            } catch (error) {
                console.error('Error fetching token:', error)
            }
        },

        async sendAlimTalk() {
            let message_template = `오늘의 책 알림이 도착했어요~${this.message}\n* 해당 오늘의 책 알림 메시지는 고객님의 알림신청에 의해 발송되었습니다.`

            try {
                const response = await this.$http.post('https://www.biztalk-api.com/v2/kko/sendAlimTalk', {
                    msgIdx: this.msgIdx,
                    countryCode: this.countryCode,
                    resMethod: this.resMethod,
                    senderKey: this.senderKey,
                    tmpltCode: this.tmpltCode,
                    message: message_template,
                    recipient: this.recipient
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'bt-token': this.token
                    }
                })

                console.log('Response:', response.data)
            } catch (error) {
                console.error('Error sending AlimTalk:', error)
            }
        },

        getResultAll() {
            this.$http.post('/api/get_result', {
                params: {
                    token: this.token
                }
            }).then((res) => {
                console.log(res)
            })
        }
    }
}
</script> 