<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">AI 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="ma-2 pa-4 pb-16">
            <p
                class="text-h6 font-weight-medium"
            >
                AI 사용 통계
            </p>


            <table
                class="tableCustom"
            >
                <tr>
                    <th>
                        북 큐레이션 챗봇
                    </th>
                    <td>
                        {{chat}}명
                    </td>
                </tr>
                <tr>
                    <th>
                        책 1/7 알림
                    </th>
                    <td>
                        {{divide}}명
                    </td>
                </tr>
            </table>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        chat: 0,
        divide: 0,
    }),

    mounted(){
        // AI 카운팅 불러오기
        this.load()
    },

    methods: {
        // AI 카운팅 불러오기
        load(){
            this.$http.post('/api/simple/ai_count/select')
            .then((res) => {
                this.chat = res.data[0].chat
                this.divide = res.data[0].divide
            })
        },
    }
}
</script>
<style scoped>
.tableCustom {
    border: 1px solid #ccc;
    border-collapse: collapse;
}
.tableCustom tr th {
    text-align: left;
    border: 1px solid #ccc;
    width:200px;
    background: #ddebf7;
    padding: 12px;
}

.tableCustom tr td {
    width:300px;
    border: 1px solid #ccc;
    padding: 12px;
}
</style>