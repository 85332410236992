<template>
    <div>
        <!-- 본문 -->
        <div
            :class="$vuetify.breakpoint.mobile? 'py-6 mb-4':'py-16 mb-16'"
        >
            <v-sheet
                class="py-16 px-8 mx-auto rounded-lg text-center"
                :width="$vuetify.breakpoint.mobile? '94%':560"
                outlined
            >
                <!-- 로고 & 문구 -->
                <div
                    class="d-flex mb-2 mx-auto"
                >
                    <v-img
                        :height="$vuetify.breakpoint.mobile? 80:120"
                        :width="$vuetify.breakpoint.mobile? 80:120"
                        contain
                        src="@/assets/logo/icon.svg"
                    ></v-img>
                </div>
                <p class="text-center text-subtitle-1 font-weight-medium mt-2 grey--text text--darken-2">
                    회원가입
                </p>

                <!-- 입력 & 버튼 -->
                <v-sheet
                    :width="$vuetify.breakpoint.mobile? '94%':420"
                    class="mt-8 mx-auto"
                >
                    <v-text-field
                        class="mb-3"
                        :class="$vuetify.breakpoint.mobile? '':'mx-16'"
                        outlined
                        v-model="user_id"
                        label="아이디 (이메일)"
                        persistent-placeholder
                        @change="$v.user_id.$touch()"
                        @blur="$v.user_id.$touch()"
                        :error-messages="user_id_Errors"
                    ></v-text-field>

                    <v-text-field
                        class="mb-3"
                        :class="$vuetify.breakpoint.mobile? '':'mx-16'"
                        outlined
                        v-model="password"
                        label="비밀번호"
                        persistent-placeholder
                        type="password"
                        @change="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                        :error-messages="password_Errors"
                    ></v-text-field>

                    <v-text-field
                        class="mb-3"
                        :class="$vuetify.breakpoint.mobile? '':'mx-16'"
                        outlined
                        v-model="password_check"
                        label="비밀번호 확인"
                        persistent-placeholder
                        type="password"
                        @change="$v.password_check.$touch()"
                        @blur="$v.password_check.$touch()"
                        :error-messages="password_check_Errors"
                    ></v-text-field>

                    <v-text-field
                        class="mb-3"
                        :class="$vuetify.breakpoint.mobile? '':'mx-16'"
                        outlined
                        v-model="nickname"
                        label="닉네임"
                        persistent-placeholder
                        @change="$v.nickname.$touch()"
                        @blur="$v.nickname.$touch()"
                        :error-messages="nickname_Errors"
                    ></v-text-field>

                    <v-text-field
                        class="mb-3"
                        :class="$vuetify.breakpoint.mobile? '':'mx-16'"
                        outlined
                        v-model="phone"
                        label="전화번호"
                        persistent-placeholder
                        @input="phone = formatPhoneNumber($event)"
                        @change="$v.phone.$touch()"
                        @blur="$v.phone.$touch()"
                        :error-messages="phone_Errors"
                    ></v-text-field>

                    <!-- 가입하기 -->
                    <div
                        :class="$vuetify.breakpoint.mobile? '':'mx-16'"
                    >
                        <v-btn
                            color="primary"
                            block
                            dark
                            x-large
                            depressed
                            @click="submit()"
                        >
                            가입하기
                        </v-btn>
                    </div>
                </v-sheet>
            </v-sheet>
        </div>
    </div>
</template>
<script>
// 검증
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength, maxLength, numeric, email } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],

    validations: {
        user_id: {
            required,
            email,
            minLength: minLength(6)
        },

        password: {
            required,
            minLength: minLength(6)
        },

        password_check: {
            required,
            sameAsPassword: sameAs('password')
        },

        nickname: {
            required
        },

        phone: {
            required,
            minLength: minLength(11),
            maxLength: maxLength(11),
            numeric
        },
    },

    data: () => ({
        // 입력값
        user_id:"",
        password:"",
        password_check:"",
        nickname:"",
        phone: "",
        email:"",
        email_type: "",

        // 중복 체크용 목록
        user_id_list: [],
        nickname_list: [],
        email_list: []
    }),

    computed: {
        // 아이디 Error 문구
        user_id_Errors () {
            const errors = []
            if (!this.$v.user_id.$dirty) return errors
            !this.$v.user_id.required && errors.push('아이디를 입력해주세요')
            !this.$v.user_id.email && errors.push('이메일 형식으로 입력해주세요')
            !this.$v.user_id.minLength && errors.push('아이디는 최소 6자 이상이여야합니다')
            this.user_id_list.includes(this.$v.user_id.$model) && errors.push('입력하신 아이디는 이미 존재합니다.')
            return errors
        },

        // 비밀번호 Error 문구
        password_Errors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('비밀번호를 입력해주세요')
            !this.$v.password.minLength && errors.push('패스워드는 최소 6자 이상이여야합니다')
            return errors
        },

        // 비밀번호 확인 Error 문구
        password_check_Errors () {
            const errors = []
            if (!this.$v.password_check.$dirty) return errors
            !this.$v.password_check.required && errors.push('비밀번호 확인을 입력해주세요')
            !this.$v.password_check.sameAsPassword && errors.push('비밀번호 확인이 같지 않습니다')
            return errors
        },

        // 닉네임 Error 문구
        nickname_Errors () {
            const errors = []
            if (!this.$v.nickname.$dirty) return errors
            !this.$v.nickname.required && errors.push('닉네임을 입력해주세요')
            this.nickname_list.includes(this.$v.nickname.$model) && errors.push('입력하신 닉네임은 이미 존재합니다.')
            return errors
        },

        // 전화번호 Error 문구
        phone_Errors () {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.required && errors.push('전화번호를 입력해주세요')
            !this.$v.phone.minLength && errors.push('전화번호는 11자리여야 합니다')
            !this.$v.phone.maxLength && errors.push('전화번호는 11자리여야 합니다')
            !this.$v.phone.numeric && errors.push('숫자만 입력해주세요')
            return errors
        },
    },

    created () {
        // 기존 유저정보 검색
        this.loadUserList()
    },

    methods: {
        // 전화번호 입력 처리
        formatPhoneNumber(value) {
            return value.replace(/[^0-9]/g, '').slice(0, 11)
        },

        // 기존 유저정보 검색
        async loadUserList(){
            await this.$http.post('/api/user/select')
            .then((res) => {
                if(res.data.length){
                    this.user_id_list = res.data.map(e => e.user_id)
                    this.nickname_list = res.data.map(e => e.nickname)
                }
            })
        },

        // 가입하기
        submit: _.debounce(async function() {
            // 입력값 기본 검증
            this.$v.$touch()

            // 기존 유저정보 검색
            await this.loadUserList()

            // 입력값 기본 검증 통과 시
            if(!this.$v.$invalid){
                // 추가 검증
                if(this.user_id_list.includes(this.user_id))
                {
                    alert("입력하신 아이디(이메일)는 이미 존재합니다.")
                }
                else if(this.nickname_list.includes(this.nickname))
                {
                    alert('입력하신 닉네임은 이미 존재합니다.')
                }
                else
                {
                    // DB 입력
                    this.$http.post('/api/user/insert', {
                        params: {
                            user_id:this.user_id,
                            password:this.password,
                            nickname:this.nickname,
                            phone:this.phone,
                            status: "미인증"
                        }
                    }).then((res) => {
                        if(!res.data.affectedRows){
                            alert("회원가입 도중 오류가 발생하였습니다.\n반복시 고객센터에 문의바랍니다.")
                        }else{
                            // alert("회원가입을 성공적으로 완료하였습니다.\n작성하신 이메일로 인증 메일이 발송되었습니다.\n메일에서 '메일 인증'버튼을 누르시면 회원가입이 완료됩니다.")
                            alert("회원가입을 성공적으로 완료하였습니다.")

                            // 인증 이메일 발송
                            // this.$http.post('/api/simple/email/send/email_certification', {
                            //     params: {
                            //         user_id: this.user_id,
                            //         email: this.user_id
                            //     }
                            // })
                            
                            this.$router.push("/")
                        }
                    })
                }   
            } 
        }, 500)
    }
}
</script>
<style scoped>
/* v-text-field, v-select */
.v-text-field--outlined >>> fieldset {
    border-color: #E0E0EB;
    border-radius: 8px;
}

.v-text-field--outlined >>> label {
    color: #336a86;
    font-size:16px;
    font-weight:400;
}

::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}
</style>