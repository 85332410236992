<template>
    <v-sheet>
        <!-- 목록 -->
        <v-sheet
            v-for="item in comment_list" :key="item.id"
            class="px-1 mx-2 pt-3 pb-4 my-1"
            style="border-top:1px solid #eee;"
        >
            <v-sheet>
                <!-- 상단 -->
                <v-sheet
                    class="d-flex align-center"
                    :class="$vuetify.breakpoint.xs? 'mb-0' : 'mb-2'"
                >
                    <!-- 프로필 -->
                    <Profile
                        :user_id="item.user_id"
                    />

                    <!-- 작성자일 시 -->
                    <v-icon
                        v-if="item.user_id == writer"
                        size="18"
                        class="ml-1 mb-6px"
                        color="blue"
                    >
                        mdi-check-circle
                    </v-icon>

                    <!-- 댓글 작성일 -->
                    <font
                        v-if="!$vuetify.breakpoint.xs"
                        class="ml-3 mr-2 mb-6px"
                        style="font-size:13px; color:#ADAFCA; line-height:18px;"
                    >
                        {{new Date(item.created).toLocaleString()}}
                    </font>

                    <!-- 댓글 삭제하기 -->
                    <v-sheet
                        v-if="item.user_id == $store.state.page_user.user_id"
                        title="댓글 삭제하기"
                        width="19"
                        height="19"
                        class="mx-1 mb-1"
                        style="cursor:pointer;"
                        @click="delete_comment(item)"
                    >
                        <v-img contain src="@/assets/board/delete.svg"></v-img>
                    </v-sheet>

                    <!-- 댓글 수정하기 -->
                    <v-sheet
                        v-if="item.user_id == $store.state.page_user.user_id"
                        title="댓글 수정하기"
                        width="19"
                        height="19"
                        class="mx-1 mb-1"
                        style="cursor:pointer;"
                        @click="item.isUpdating = !item.isUpdating"
                    >
                        <v-img contain src="@/assets/board/update.svg"></v-img>
                    </v-sheet>

                    <v-spacer></v-spacer>

                    <!-- 좋아요 -->
                    <v-btn
                        class="mr-2 px-2"
                        v-ripple="false"
                        text
                        @click="comment_like(item)"
                    >
                        <v-img width="20" src="@/assets/board/like_default.png"></v-img>
                        <span class="text-caption font-weight-medium ml-2 mb-1">
                            좋아요 {{item.like_count}}
                        </span>
                    </v-btn>
                </v-sheet>

                <!-- 댓글 내용 -->
                <div
                    v-if="!item.isUpdating"
                    class="pa-3"
                    style="white-space:pre-line; font-size:13px;"
                    v-html="$linkify(item.content)"
                ></div>

                <!-- 수정 시 -->
                <div class="mt-4">
                    <!-- 내용 -->
                    <v-sheet v-if="item.isUpdating" outlined class="d-flex justify-center py-1">
                        <v-textarea
                            hide-details
                            flat
                            solo
                            v-model="item.content"
                            style="font-size:13px;"
                            placeholder="평가 내용을 입력해주세요"
                        ></v-textarea>
                    </v-sheet>

                    <!-- 수정, 취소 버튼 -->
                    <div v-if="item.isUpdating" class="mt-2 mb-4 d-flex justify-end">
                        <v-btn
                            width="90"
                            height="35"
                            class="mr-2"
                            color="grey"
                            outlined
                            @click="comment_update_submit(item)"
                        >
                            수정
                        </v-btn>
                        <v-btn
                            width="90"
                            height="35"
                            color="grey"
                            outlined
                            @click="item.isUpdating = false; load()"
                        >
                            취소
                        </v-btn>
                    </div>
                </div>

                <div class="d-flex justify-end">
                    <v-btn
                        small
                        depressed
                        text
                        @click="item.isSubWriting = !item.isSubWriting"
                    >
                        답글
                    </v-btn>
                </div>

                <!-- 대댓글 목록 -->
                <SubCommentList
                    v-if="item.sub_comment_count"
                    :comment_id="item.id"
                    :writer="writer"
                    :key="componentKey"
                />

                <!-- 대댓글 쓰기 -->
                <v-sheet
                    v-if="item.isSubWriting"
                    class="mt-2"
                >
                    <!-- 입력칸 -->
                    <div class="d-flex">
                        <v-sheet class="mr-7">
                            <v-icon size="20">mdi-arrow-right-bottom</v-icon>
                        </v-sheet>
                        <v-sheet outlined class="py-1" width="100%">
                            <v-textarea
                                style="font-size:13px;"
                                hide-details 
                                flat
                                solo
                                v-model="subComment_content"
                            ></v-textarea>
                        </v-sheet>
                    </div>

                    <!-- 댓글 입력 Submit -->
                    <v-sheet class="d-flex mt-4 pb-1">
                        <v-spacer></v-spacer>
                        <v-btn
                            width="120"
                            height="35"
                            color="grey"
                            outlined
                            @click="subComment_write(item)"
                        >
                            대댓글 쓰기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-sheet>
        
        <!-- 댓글 쓰기 -->
        <v-sheet class="px-2">
            <!-- 입력칸 -->
            <v-sheet outlined class="py-1">
                <v-textarea
                    style="font-size:13px;"
                    hide-details 
                    flat
                    solo
                    v-model="comment_content"
                ></v-textarea>
            </v-sheet>

            <!-- 댓글 입력 Submit -->
            <v-sheet class="d-flex mt-4 pb-1">
                <!-- 목록 -->
                <v-btn
                    :x-small="$vuetify.breakpoint.xs"
                    dark
                    depressed
                    :height="$vuetify.breakpoint.xs? 28 : 35"
                    :width="$vuetify.breakpoint.xs? null : 80"
                    color="grey"
                    outlined
                    @click="backToList()"
                >
                    목록
                </v-btn>

                <!-- 글쓴이일 경우 또는 관리자일 경우 -->
                <div
                    v-if="writer==$store.state.page_user.user_id || $store.state.page_user.type=='관리자'"
                    class="ml-2 d-flex"
                >
                    <!-- <v-btn
                        :x-small="$vuetify.breakpoint.xs"
                        :height="$vuetify.breakpoint.xs? 28 : 35"
                        :width="$vuetify.breakpoint.xs? null : 80"
                        color="grey"
                        outlined
                        @click="updatePost()"
                    >
                        수정
                    </v-btn> -->
                    <!-- <v-btn
                        v-if="$route.query.type == 'my'"
                        :x-small="$vuetify.breakpoint.xs"
                        :height="$vuetify.breakpoint.xs? 28 : 35"
                        :width="$vuetify.breakpoint.xs? null : 80"
                        color="grey"
                        outlined
                        @click="updateShow()"
                    >
                        공개수정
                    </v-btn> -->

                    <v-btn
                        :x-small="$vuetify.breakpoint.xs"
                        :height="$vuetify.breakpoint.xs? 28 : 35"
                        :width="$vuetify.breakpoint.xs? null : 80"
                        color="grey"
                        outlined
                        :class="$vuetify.breakpoint.mobile? '' : 'ml-2'"
                        @click="deletePost()"
                    >
                        삭제
                    </v-btn>

                    <v-checkbox
                        :class="$vuetify.breakpoint.mobile? 'ml-2' : 'ml-4'"
                        v-model="is_show"
                        :label="is_show? '공개' : '비공개'"
                        hide-details
                        dense
                        :style="$vuetify.breakpoint.mobile? 'margin-top:-2px;' : 'margin-top:1px;'"
                        @change="updateShow()"
                    ></v-checkbox>
                </div>
                <v-spacer></v-spacer>

                <v-btn
                    :x-small="$vuetify.breakpoint.xs"
                    :height="$vuetify.breakpoint.xs? 28 : 35"
                    :width="$vuetify.breakpoint.xs? null : 100"
                    color="grey"
                    outlined
                    @click="comment_write()"
                >
                    댓글 쓰기
                </v-btn>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
import SubCommentList from './comment/subcomment'
import Profile from "./profile"

export default {
    props: [
        "title",
        "writer",
        "is_show"
    ],

    components: {
        SubCommentList,
        Profile
    },

    data: () => ({
        comment_content: "",
        comment_list: [],

        subComment_content: "",
        componentKey: 0
    }),

    mounted(){
        // 댓글 목록
        this.load()
    },

    methods: {
        // 댓글 목록
        load(){
            this.$http.post('/api/post/comment/select', {
                params: {
                    post_id:this.$route.query.id
                }
            }).then((res) => {
                this.comment_list = res.data
                this.$emit("updated")
            })
        },

        // 댓글 달기
        comment_write(){
            if(!this.$store.state.page_user.is_logined){
                alert("댓글 달기는 로그인 이후 가능합니다.")
            }else if(this.$store.state.page_user.type == "밴 유저"){
                alert('밴 유저는 밴 기간동안 댓글 달기가 제한됩니다.')
            }else{
                if(this.comment_content == ""){
                    alert("댓글 내용을 입력해주세요")
                }else{
                    // 댓글 입력
                    this.$http.post('/api/post/comment/insert', {
                        params: {
                            post_id: this.$route.query.id,
                            user_id: this.$store.state.page_user.user_id,
                            content: this.comment_content
                        }
                    }).then((res) => {
                        this.comment_content = ""

                        // 댓글 목록
                        this.load()   
                    })
                }
            }
        },

        // 댓글 삭제
        delete_comment(item){
            if(confirm("정말 해당 댓글을 삭제하시겠습니까?")){
                this.$http.post('/api/post/comment/delete', {
                    params: {
                        id: item.id
                    }
                }).then(() => {
                    alert('댓글이 삭제되었습니다.')

                    // 댓글 목록
                    this.load()
                })
            }
        },

        // 댓글 수정
        comment_update_submit(item){
            this.$http.post('/api/post/comment/update', {
                params: {
                    id :item.id,
                    content :item.content
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    // 댓글 목록
                    this.load()
                }
            })
        },

        // 댓글 추천
        comment_like(item){
            if(!this.$store.state.page_user.is_logined){
                alert("댓글 추천은 로그인 이후 가능합니다.")
            }else if(this.$store.state.page_user.user_id == item.user_id){
                alert('자신의 댓글에 추천을 할 수 없습니다.')
            }else{
                this.$http.post('/api/post/comment/like/list', {
                    params: {
                        comment_id: item.id,
                        user_id: this.$store.state.page_user.user_id
                    }
                }).then((res) => {
                    if(res.data.length){
                        alert("이미 추천한 댓글입니다.")
                    }else{
                        this.$http.post('/api/post/comment/like', {
                            params: {
                                comment_id: item.id,
                                user_id: this.$store.state.page_user.user_id
                            }
                        }).then((res) => {
                            // 댓글 목록
                            this.load()
                        })
                    }
                })
            }
        },

        // 대댓글 달기
        subComment_write(item){
            if(!this.$store.state.page_user.is_logined){
                alert("댓글 달기는 로그인 이후 가능합니다.")
            }else{
                if(this.subComment_content == ""){
                    alert("대댓글 내용을 입력해주세요")
                }else{
                    // 대댓글 입력
                    this.$http.post('/api/post/subcomment/insert', {
                        params: {
                            post_id: this.$route.query.id,
                            comment_id: item.id,
                            user_id: this.$store.state.page_user.user_id,
                            content: this.subComment_content
                        }
                    }).then((res) => {
                        if(res.data.affectedRows){
                            // 초기화
                            this.subComment_content = ""

                            // 댓글 목록
                            this.load()

                            // 대댓글 목록 새로고침
                            this.componentKey++
                        } 
                    })
                }
            }
        },

        // 목록
        backToList(){
            this.$router.push('/post/list?type='+this.$route.query.type)
        },

        // 수정하기
        updatePost(){
            this.$router.push(this.$route.path + "?type=update&id="+this.$route.query.id)
        },

        // 삭제하기
        deletePost(){
            if(confirm("정말 해당 글을 삭제하시겠습니까?")){
                this.$http.post('/api/post/delete', {
                    params: {
                        id : this.$route.query.id
                    }
                }).then(() => {
                    this.$router.push('/post/list?type='+this.$route.query.type)
                })
            }
        },

        // 공개수정
        updateShow(){
            console.log(this.is_show)
            this.$http.post('/api/post/update/show', {
                params: {
                    id: this.$route.query.id,
                    show: this.is_show
                }
            }).then(() => {
                alert('공개 상태가 수정되었습니다.')
            })
        }
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}
</style>
<style scoped>
.v-btn::before {
    background-color: transparent;
}
</style>