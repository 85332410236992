<template>
    <v-sheet
        :width="$vuetify.breakpoint.mobile? '100%':'1000px'"
        class="pa-4 mt-2 rounded-10 mx-auto"
        style="position:relative;"
    >
        <!-- 제목 -->
        <p class="text-h5 text-center font-weight-medium">
            게시글 작성
        </p>

        <!-- 제목 -->
        <v-sheet
            width="100%"
            class="pa-1 rounded-10"
            outlined
        >
            <v-text-field
                hide-details
                dense
                placeholder="제목을 입력해주세요"
                solo
                flat
                v-model="title"
            ></v-text-field>
        </v-sheet>

        <!-- 본문 -->
        <TipTapWriter 
            class="tiptap_style mt-2"
            :options="options"
            :key="componentKey"
        />

        <!-- 버튼 -->
        <div
            class="d-flex justify-center pa-1 mt-2 mb-10"
        >
            <v-btn
                class="mx-2 px-10 rounded-10"
                color="#ADAFCA"
                dark
                large
                depressed
                @click="cancel()"
            >
                취소하기
            </v-btn>
            <v-btn
                class="mx-2 px-10 rounded-10"
                color="primary"
                dark
                large
                depressed
                @click="submit()"
            >
                등록하기
            </v-btn>
        </div>
    </v-sheet>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"

export default {
    components: {
        TipTapWriter
    },

    data: () => ({
        title: '',

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        },

        componentKey: 0,
    }),

    methods: {
        // 취소하기
        cancel(){
            if(confirm("지금 작성하고 계신 글이 저장되지 않습니다.\n페이지를 이동하시겠습니까?")){
                this.$router.go(-1)
            }
        },

        // 등록하기
        submit(){
            if(!this.title.length)
            {
                alert("제목을 입력해주세요")
            }
            else if(!this.options.content.length)
            {
                alert("내용을 입력해주세요")
            }
            else
            {
                this.$http.post('/api/post/insert', {
                    params: {
                        type: this.$route.query.type == 'free' ? 'free' : 'review',
                        user_id: this.$store.state.page_user.user_id,
                        title: this.title,
                        content: this.options.content
                    }
                }).then((res) => {
                    if(res.data.affectedRows)
                    {
                        this.$router.push(`/post/list?type=${this.$route.query.type}`)
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
.table_style{
    width:100%;
    background:white;
    border:1px solid #ddd;
    border-collapse: collapse;
}

.table_style tr td{
    border:1px solid #ddd;
    padding:4px;
    text-align: center;
}

.filled{
    background:#ddd;
    color:#999;
    font-weight:300;
}

.reservation{
    background:#bba8ca;
    color:#84679a;
    font-weight:300;
}
</style>