<template>
    <div>
        <!-- 본문 -->
        <div
            :class="$vuetify.breakpoint.mobile? 'py-6 mb-4':'py-16 mb-16'"
        >
            <v-sheet
                class="py-16 px-8 mx-auto rounded-lg text-center"
                :width="$vuetify.breakpoint.mobile? '94%':480"
            >
                <!-- 로고 & 문구 -->
                <div
                    class="mb-10 mx-auto d-flex"
                >
                    <v-img
                        :height="$vuetify.breakpoint.mobile? 80:120"
                        :width="$vuetify.breakpoint.mobile? 80:120"
                        contain
                        src="@/assets/logo/icon.svg"
                    ></v-img>
                </div>

                <v-sheet
                    :width="$vuetify.breakpoint.mobile? '94%':280"
                    class="mx-auto"
                >
                    <!-- 아이디(이메일) -->
                    <v-text-field
                        outlined
                        flat
                        dense
                        autofocus
                        placeholder="아이디(이메일)"
                        v-model="user_id"
                        @change="$v.user_id.$touch()"
                        @blur="$v.user_id.$touch()"
                        :error-messages="user_id_Errors"
                        @keyup.enter="submit()"
                    >
                    </v-text-field>

                    <!-- 비밀번호 -->
                    <v-text-field
                        outlined
                        flat
                        dense
                        type="password"
                        placeholder="비밀번호"
                        v-model="password"
                        @change="$v.password.$touch()"
                        @blur="$v.password.$touch()"
                        :error-messages="password_Errors"
                        @keyup.enter="submit()"
                    >
                    </v-text-field>

                    <!-- 로그인 버튼 -->
                    <v-btn
                        class="font-weight-bold"
                        color="primary"
                        block
                        dark
                        large
                        depressed
                        @click="submit()"
                    >
                        로그인
                    </v-btn>
                </v-sheet>

                <!-- 버튼 -->
                <div class="d-flex justify-center mt-4">
                    <v-btn
                        width="90"
                        color="grey darken-1"
                        small
                        text
                        to="/auth/find/id"
                    >
                        아이디 찾기
                    </v-btn>
                    <v-btn
                        width="90"
                        :class="$vuetify.breakpoint.mobile? '':'mx-2'"
                        color="grey darken-1"
                        small
                        text
                        to="/auth/find/password"
                    >
                        비밀번호 찾기
                    </v-btn>
                    <v-btn
                        width="90"
                        color="grey darken-1"
                        small
                        text
                        to="/auth/join/agree"
                    >
                        회원가입
                    </v-btn>
                </div>
            </v-sheet>
        </div>
    </div>
</template>
<script>
// 검증
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'

export default {
    mixins: [validationMixin],

    validations: {
        user_id: {
            required
        },
        password: {
            required
        }
    },

    data: () => ({
        user_id: "",
        password: ""
    }),

    computed: {
        user_id_Errors () {
            const errors = []
            if (!this.$v.user_id.$dirty) return errors
            !this.$v.user_id.required && errors.push('아이디를 입력해주세요')
            return errors
        },

        password_Errors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('비밀번호를 입력해주세요')
            return errors
        }
    },

    methods: {
        ...mapMutations(['login']),

        submit(){
            this.$v.$touch()

            if (!this.$v.$invalid) {
                // 아이디 & 비밀번호 확인
                this.$http.post("/api/user/select/login", {
                    params: {
                        user_id: this.user_id,
                        password:this.password
                    }
                }).then((res) => {
                    // 일치하지 않을 시
                    if(!res.data.length){
                        // 임시 비밀번호 확인
                        this.$http.post("/api/simple/temp_password/select/check", {
                            params: {
                                user_id: this.user_id,
                                password: this.password
                            }
                        }).then((res) => {
                            if(!res.data.length){
                                alert("아이디 또는 비밀번호가 잘못되었습니다.")
                            }else{
                                // 이메일 인증여부 확인 & 로그인
                                this.emailCheckAndLogin(res)
                            }
                        })
                    // 일치 시
                    }else{
                        // 이메일 인증여부 확인 & 로그인
                        this.emailCheckAndLogin(res)
                    }
                })
            }
        },

        // 이메일 인증여부 확인 & 로그인
        emailCheckAndLogin(res){
            this.$http.post("/api/user/select/specific", {
                params: {
                    user_id: this.user_id
                }
            }).then((res2) => {
                // 이메일 인증이 안되었을 시
                if(res2.data[0].status != "인증완료"){
                    alert("이메일 인증이 완료되지 않은 아이디입니다,\n이메일 인증을 완료 후 로그인해주세요.")
                }
                // 이메일 인증이 되었을 시
                else{
                    // 로그인
                    this.login(res.data[0])
                    this.$router.push("/")
                }
            })
        }
    }
}
</script>