<template>
    <div>
        <!-- 본문 -->
        <div
            :class="$vuetify.breakpoint.mobile? 'py-6 mb-4':'py-16 mb-16'"
        >
            <v-sheet
                class="py-16 px-8 mx-auto rounded-lg text-center"
                :width="$vuetify.breakpoint.mobile? '94%':480"
                outlined
            >
                <!-- 로고 & 문구 -->
                <div
                    class="d-flex mb-2 mx-auto"
                >
                    <v-img
                        :height="$vuetify.breakpoint.mobile? 80:120"
                        :width="$vuetify.breakpoint.mobile? 80:120"
                        contain
                        src="@/assets/logo/icon.svg"
                    ></v-img>
                </div>
                <p
                    class="mb-6 text-subtitle-1 font-weight-medium grey--text text--darken-2"
                >
                    비밀번호 찾기
                </p>

                <v-sheet
                    :width="$vuetify.breakpoint.mobile? '94%':280"
                    class="mx-auto"
                >
                    <!-- 입력 -->
                    <v-text-field
                        outlined
                        flat
                        dense
                        v-model="user_id"
                        autofocus
                        hide-details
                        placeholder="아이디(이메일)"
                        @keyup.enter="submit()"
                    >
                    </v-text-field>
                    <p class="mt-2 mb-8 text-caption text-center">
                        가입하신 이메일로 임시 비밀번호 발송해드립니다
                    </p>

                    <!-- 발송 -->
                    <v-btn
                        class="font-weight-bold"
                        color="primary"
                        dark
                        block
                        large
                        depressed
                        @click="submit()"
                    >
                        이메일 발송
                    </v-btn>
                </v-sheet>
                
                <!-- 버튼 -->
                <div class="d-flex justify-center mt-4">
                    <v-btn
                        class="mr-1"
                        color="grey darken-1"
                        text
                        small
                        to="/auth/login"
                    >
                        로그인으로 돌아가기
                    </v-btn>
                    <v-btn
                        color="grey darken-1"
                        text
                        small
                        to="/auth/find/id"
                    >
                        아이디 찾기
                    </v-btn>
                </div>
            </v-sheet>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        user_id: ""
    }),

    methods: {
        submit(){
            // 이메일로 회원 찾기
            this.$http.post('/api/user/select/specific', {
                params: {
                    user_id: this.user_id
                }
            }).then((res) => {
                if(!res.data.length){
                    alert("해당 이메일로 가입된 회원이 없습니다.")
                }else{
                    // 이메일 발송
                    this.$http.post('/api/simple/email/send/find_password', {
                        params: {
                            email: this.user_id,
                            user_id: res.data[0].user_id
                        }
                    }).then((res) => {
                        if(res.data){
                            alert("임시 비멀번호 이메일이 정상적으로 발송되었습니다.")
                        }
                    })
                }
            })
        }
    }
}
</script>