<template>
    <div>
        <!-- 본문 -->
        <div
            :class="$vuetify.breakpoint.mobile? 'py-6 mb-4':'py-16 mb-16'"
        >
            <v-sheet
                class="py-16 px-8 mx-auto rounded-lg text-center"
                :width="$vuetify.breakpoint.mobile? '94%':480"
                outlined
            >
                <!-- 로고 & 문구 -->
                <div
                    class="d-flex mb-2 mx-auto"
                >
                    <v-img
                        :height="$vuetify.breakpoint.mobile? 80:120"
                        :width="$vuetify.breakpoint.mobile? 80:120"
                        contain
                        src="@/assets/logo/icon.svg"
                    ></v-img>
                </div>
                <p class="text-subtitle-1 font-weight-medium mt-2 grey--text text--darken-2">
                    회원가입
                </p>
                <p class="text-caption grey--text text--darken-1">
                    필수 이용약관 확인 후 동의해주시기 바랍니다
                </p>

                <!-- 약관 동의 -->
                <v-sheet
                    :width="$vuetify.breakpoint.mobile? '94%':280"
                    class="mx-auto"
                >
                    <!-- 이용약관 -->
                    <v-sheet class="mt-10 d-flex">
                        <!-- 체크박스 -->
                        <v-checkbox
                            style="padding-top:3px !important"
                            class="ma-0 pa-0"
                            color="primary"
                            v-model="rule"
                            :error-messages="rule_Errors"
                            label="이용약관"
                            required
                            @change="$v.rule.$touch()"
                            @blur="$v.rule.$touch()"
                        ></v-checkbox>
                        <v-spacer></v-spacer>

                        <!-- 약관보기 -->
                        <router-link
                            to="/policy/rule?type=read"
                            style="cursor:pointer; text-decoration:none !important;"
                        >
                            <p class="text-subtitle-2 font-weight-bold mt-3px mr-3 primary--text">
                                <span v-if="!$vuetify.breakpoint.mobile">약관보기</span>
                                <v-icon size="20" class="ml-1 mb-2px" color="primary">
                                    mdi-open-in-new
                                </v-icon>
                            </p>
                        </router-link>
                    </v-sheet>

                    <!-- 개인정보처리방침 -->
                    <v-sheet class="d-flex">
                        <!-- 체크박스 -->
                        <v-checkbox
                            style="padding-top:3px !important"
                            class="ma-0 pa-0"
                            color="primary"
                            v-model="privacy"
                            :error-messages="privacy_Errors"
                            label="개인정보처리방침"
                            required
                            @change="$v.privacy.$touch()"
                            @blur="$v.privacy.$touch()"
                        ></v-checkbox>
                        <v-spacer></v-spacer>

                        <!-- 약관보기 -->
                        <router-link
                            to="/policy/privacy?type=read"
                            style="cursor:pointer; text-decoration:none !important;"
                        >
                            <p class="text-subtitle-2 font-weight-bold mt-3px mr-3 primary--text">
                                <span v-if="!$vuetify.breakpoint.mobile">약관보기</span>
                                <v-icon size="20" class="ml-1 mb-2px" color="primary">
                                    mdi-open-in-new
                                </v-icon>
                            </p>
                        </router-link>
                    </v-sheet>
                    
                    <!-- 버튼 -->
                    <v-sheet class="mt-6">
                        <v-btn
                            color="primary"
                            block
                            large
                            dark
                            depressed
                            @click="submit()"
                        >
                            진행하기
                        </v-btn>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </div>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'

export default {
    mixins: [validationMixin],

    validations: {
        rule: {
            checked (val) {
                return val
            }
        },

        privacy: {
            checked (val) {
                return val
            }
        }
    },

    data: () => ({
        rule:false,
        privacy:false,
        submitStatus: null
    }),

    computed: {
        rule_Errors () {
            const errors = []
            if (!this.$v.rule.$dirty) return errors
            !this.$v.rule.checked && errors.push('이용약관에 동의해주세요')
            return errors
        },
        privacy_Errors () {
            const errors = []
            if (!this.$v.privacy.$dirty) return errors
            !this.$v.privacy.checked && errors.push('개인정보처리방침에 동의해주세요')
            return errors
        }
    },

    methods: {
        // 진행하기
        submit () {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.$router.push('/auth/join/form')
            }
        }
    }
}
</script>
<style>
  .v-input--checkbox .v-label {
    font-size: 15px;
    font-weight: bold;
  }
</style>