<template>
    <div>
        <!-- # PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
        >
            <!-- # 섹션 0 -->
            <v-sheet
                class="pa-5 pt-14 pb-12"
                color="#fffdef"
            >
                <p
                    class="banner_font text-center mb-0"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                >
                    책 후기
                </p>

                <v-row
                    v-if="list.length"
                    :class="$vuetify.breakpoint.mobile? 'px-4':''"
                    :style="{ justifyContent: list.length < 4 ? 'center' : 'flex-start' }"
                    class="pt-10 pb-4"
                >
                    <v-col
                        v-for="item in list" :key="item.id"
                        :cols="$vuetify.breakpoint.mobile? 12:3"
                        :class="$vuetify.breakpoint.mobile? 'px-2':'px-6'"
                    >
                        <v-card
                            :class="$vuetify.breakpoint.mobile? '':'mb-10'"
                            class="rounded-10"
                            style="cursor:pointer; box-shadow: 0px 4px 16px #ddd;"
                            @click="read(item)"
                        >
                            <!-- 썸네일 -->
                            <v-img
                                height="200"
                                style="border-radius: 5px 5px 0 0;"
                                :src="item.image? item.image : require('@/assets/board/thumbnail.png')"
                            ></v-img>

                            <!-- 텍스트 -->
                            <v-sheet class="pt-4 pl-5 pr-6 pb-2 rounded-10">
                                <div class="d-flex justify-space-between">
                                    <v-sheet width="240">
                                        <!-- 제목 -->
                                        <p class="text-subtitle-1 font-weight-medium mb-0 text-truncate pr-2" style="line-height:22px;">
                                            {{item.title}}
                                        </p>
                                    </v-sheet>
                                </div>

                                <!-- 닉네임 & 조회수 & 좋아요 & 댓글 -->
                                <v-sheet width="100%" class="d-flex align-center justify-end">
                                    <!-- 닉네임 & 조회수 -->
                                    <span class="text-caption">
                                        {{item.nickname}}ㆍ조회수 {{item.view_count}}
                                    </span>
                                    <v-spacer></v-spacer>

                                    <!-- 좋아요 -->
                                    <v-sheet>
                                        <v-img
                                            class="mr-2 mt-1px"
                                            width="17"
                                            src="@/assets/board/like_default.png"
                                        ></v-img>
                                    </v-sheet>
                                    <span class="text-body-1 mb-1px font-weight-medium">
                                        {{item.like_count}}
                                    </span>

                                    <!-- 댓글 -->
                                    <v-sheet>
                                        <v-img
                                            class="ml-4 mr-2"
                                            width="14"
                                            src="@/assets/board/comment.png"
                                        ></v-img>
                                    </v-sheet>
                                    <span class="text-body-1 mb-1px font-weight-medium">
                                        {{item.comment_count}}
                                    </span>
                                </v-sheet>
                            </v-sheet>
                        </v-card>
                    </v-col>
                </v-row>

                <v-btn
                    width="200"
                    class="d-flex mx-auto mt-4 rounded-10"
                    dark
                    large
                    depressed
                    color="#079aaa"
                    data-aos="fade-up"
                    data-aos-duration="2600"
                    @click="$router.push('/post/list?type=review')"
                >
                    책 후기 보러가기
                </v-btn>
            </v-sheet>

            <!-- # 섹션 1 -->
            <v-sheet
                class="pa-5 pt-14 pb-12"
                color="#f8faff"
            >
                <p
                    class="banner_font text-center mb-0"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                >
                    매일 새로운 책을 발견하다
                </p>

                <v-img
                    class="mx-auto"
                    width="520"
                    height="420"
                    contain
                    src="@/assets/home/section1.png"
                ></v-img>

                <div
                    class="text-center"
                >
                    <p
                        class="request_font mb-0"
                        data-aos="fade-up"
                        data-aos-duration="2200"
                    >
                        "마음의 평안을 줄 책을 추천해줘"
                    </p>

                    <p
                        class="request_font mb-0"
                        data-aos="fade-up"
                        data-aos-duration="2400"
                    >
                        "재테크 관련 책을 추천해줘"
                    </p>

                    <p
                        class="request_font mb-0"
                        data-aos="fade-up"
                        data-aos-duration="2600"
                    >
                        "자기 계발 관련 책을 추천해줘"
                    </p>
                </div>
                <v-btn
                    width="200"
                    class="d-flex mx-auto mt-4 rounded-10"
                    dark
                    large
                    depressed
                    color="#079aaa"
                    data-aos="fade-up"
                    data-aos-duration="2600"
                    @click="$router.push('/service/book')"
                >
                    오늘의 책 알림 시작하기
                </v-btn>
            </v-sheet>

            <!-- # 섹션 2 -->
            <v-sheet
                class="pt-4 pb-120px"
                color="#fffdef"
            >
                <div
                    class="d-flex mx-auto justify-center"
                    style="width:1200px; padding:40px 160px; padding-bottom:40px;"
                >
                    <div>
                        <v-img
                            class="d-flex justify-center mx-auto"
                            width="240"
                            height="220"
                            contain
                            src="@/assets/home/section2.png"
                        ></v-img>
                        
                        <p
                            class="banner_font text-center mb-1"
                            style="line-height:58px; font-size:50px;"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                        >
                            책 이름을 알려주세요
                        </p>

                        <p
                            class="banner_font text-center mb-0"
                            style="line-height:48px; font-size:32px;"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                        >
                            읽기 전에 요약해드릴게요
                        </p>
                    </div>
                </div>

                <v-btn
                    width="200"
                    class="d-flex mx-auto rounded-10"
                    dark
                    large
                    depressed
                    color="#01ada4"
                    data-aos="fade-up"
                    data-aos-duration="2600"
                    @click="$router.push('/service/search')"
                >
                    책 요약 받기
                </v-btn>
            </v-sheet>

            <!-- # 섹션 3 -->
            <v-sheet
                class="pt-16 pb-80px"
                color="#ddede3"
            >
                <p
                    class="banner_font text-center mb-0"
                    style="font-size:44px;"
                    data-aos="fade-up"
                    data-aos-duration="3200"
                >
                    매일 새로운 글귀를 보내드립니다
                </p>

                <v-img
                    class="mx-auto"
                    width="580"
                    height="420"
                    contain
                    src="@/assets/home/section3.png"
                ></v-img>

                <div
                    class="text-center"
                >
                    <p
                        class="request_font mb-0"
                        data-aos="fade-up"
                        data-aos-duration="3200"
                    >
                        "응원이 되는 글을 보내줘"
                    </p>

                    <p
                        class="request_font mb-0"
                        data-aos="fade-up"
                        data-aos-duration="3200"
                    >
                        "동기 부여가 되는 글을 보내줘"
                    </p>

                    <p
                        class="request_font mb-0"
                        data-aos="fade-up"
                        data-aos-duration="3200"
                    >
                        "여행 관련 글을 보내줘"
                    </p>
                </div>

                <v-btn
                    width="200"
                    class="d-flex mx-auto rounded-10 mt-6"
                    dark
                    large
                    depressed
                    color="#079aaa"
                    data-aos="fade-up"
                    data-aos-duration="2600"
                    @click="$router.push('/service/text')"
                >
                    오늘의 글 알림 시작하기
                </v-btn>
            </v-sheet>
        </div>

        <!-- # Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
        >
            <!-- # 섹션 0 -->
            <v-sheet
                class="pa-5 pt-8 pb-12"
                color="#fffdef"
            >
                <p
                    class="banner_font text-center mb-0"
                    style="font-size:28px;"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                >
                    책 후기
                </p>

                <v-row
                    v-if="list.length"
                    :class="$vuetify.breakpoint.mobile? 'px-4':''"
                    :style="{ justifyContent: list.length < 4 ? 'center' : 'flex-start' }"
                    class="pt-4 pb-4"
                >
                    <v-col
                        v-for="item in list.slice(0, 4)" :key="item.id"
                        :cols="$vuetify.breakpoint.mobile? 12:3"
                        :class="$vuetify.breakpoint.mobile? 'px-2':'px-6'"
                    >
                        <v-card
                            :class="$vuetify.breakpoint.mobile? '':'mb-10'"
                            class="rounded-10"
                            style="cursor:pointer; box-shadow: 0px 4px 16px #ddd;"
                            @click="read(item)"
                        >
                            <!-- 썸네일 -->
                            <v-img
                                height="200"
                                style="border-radius: 5px 5px 0 0;"
                                :src="item.image? item.image : require('@/assets/board/thumbnail.png')"
                            ></v-img>

                            <!-- 텍스트 -->
                            <v-sheet class="pt-4 pl-5 pr-6 pb-2 rounded-10">
                                <div class="d-flex justify-space-between">
                                    <v-sheet width="240">
                                        <!-- 제목 -->
                                        <p class="text-subtitle-1 font-weight-medium mb-0 text-truncate pr-2" style="line-height:22px;">
                                            {{item.title}}
                                        </p>
                                    </v-sheet>
                                </div>

                                <!-- 닉네임 & 조회수 & 좋아요 & 댓글 -->
                                <v-sheet width="100%" class="d-flex align-center justify-end">
                                    <!-- 닉네임 & 조회수 -->
                                    <span class="text-caption">
                                        {{item.nickname}}ㆍ조회수 {{item.view_count}}
                                    </span>
                                    <v-spacer></v-spacer>

                                    <!-- 좋아요 -->
                                    <v-sheet>
                                        <v-img
                                            class="mr-2 mt-1px"
                                            width="17"
                                            src="@/assets/board/like_default.png"
                                        ></v-img>
                                    </v-sheet>
                                    <span class="text-body-1 mb-1px font-weight-medium">
                                        {{item.like_count}}
                                    </span>

                                    <!-- 댓글 -->
                                    <v-sheet>
                                        <v-img
                                            class="ml-4 mr-2"
                                            width="14"
                                            src="@/assets/board/comment.png"
                                        ></v-img>
                                    </v-sheet>
                                    <span class="text-body-1 mb-1px font-weight-medium">
                                        {{item.comment_count}}
                                    </span>
                                </v-sheet>
                            </v-sheet>
                        </v-card>
                    </v-col>
                </v-row>

                <v-btn
                    width="200"
                    class="d-flex mx-auto mt-4 rounded-10"
                    dark
                    large
                    depressed
                    color="#079aaa"
                    data-aos="fade-up"
                    data-aos-duration="2600"
                    @click="$router.push('/post/list?type=review')"
                >
                    책 후기 보러가기
                </v-btn>
            </v-sheet>

            <!-- # 섹션 1 -->
            <v-sheet
                class="pt-12 pb-16"
                color="#f8faff"
            >
                <p
                    class="banner_font text-center mb-0"
                    style="font-size:22px;"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                >
                    매일 새로운 책을 발견하다
                </p>

                <v-img
                    class="mx-auto my-4"
                    width="70%"
                    contain
                    src="@/assets/home/section1.png"
                ></v-img>

                <div
                    class="text-center"
                >
                    <p
                        class="request_font mb-0"
                        style="font-size:14px;"
                        data-aos="fade-up"
                        data-aos-duration="2200"
                    >
                        "마음의 평안을 줄 책을 추천해줘"
                    </p>

                    <p
                        class="request_font mb-0"
                        style="font-size:14px;"
                        data-aos="fade-up"
                        data-aos-duration="2400"
                    >
                        "재테크 관련 책을 추천해줘"
                    </p>

                    <p
                        class="request_font mb-0"
                        style="font-size:14px;"
                        data-aos="fade-up"
                        data-aos-duration="2600"
                    >
                        "자기 계발 관련 책을 추천해줘"
                    </p>
                </div>
                <v-btn
                    width="200"
                    class="d-flex mx-auto mt-6 rounded-10"
                    dark
                    large
                    depressed
                    color="#079aaa"
                    data-aos="fade-up"
                    data-aos-duration="2600"
                    @click="$router.push('/service/book')"
                >
                    오늘의 책 알림 시작하기
                </v-btn>
            </v-sheet>

            <!-- # 섹션 2 -->
            <v-sheet
                class="pt-10 pb-76px"
                color="#fffdef"
            >
                <div>
                    <v-img
                        class="d-flex justify-center mx-auto"
                        width="70%"
                        contain
                        src="@/assets/home/section2.png"
                    ></v-img>
                    
                    <p
                        class="banner_font text-center mb-0 mt-6"
                        style="font-size:26px;"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                    >
                        책 이름을 알려주세요
                    </p>

                    <p
                        class="banner_font text-center mb-0"
                        style="font-size:18px;"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                    >
                        읽기 전에 요약해드릴게요
                    </p>
                </div>

                <v-btn
                    width="200"
                    class="d-flex mx-auto mt-6 rounded-10"
                    dark
                    large
                    depressed
                    color="#01ada4"
                    data-aos="fade-up"
                    data-aos-duration="2600"
                    @click="$router.push('/service/search')"
                >
                    책 요약 받기
                </v-btn>
            </v-sheet>

            <!-- # 섹션 3 -->
            <v-sheet
                class="pt-16 pb-16"
                color="#ddede3"
            >
                <p
                    class="banner_font text-center mb-0"
                    style="font-size:28px;"
                    data-aos="fade-up"
                    data-aos-duration="3200"
                >
                    매일 새로운 글귀를<br/>
                    보내드립니다
                </p>

                <v-img
                    class="mx-auto"
                    width="80%"
                    contain
                    src="@/assets/home/section3.png"
                ></v-img>

                <div
                    class="text-center"
                >
                    <p
                        class="request_font mb-0"
                        style="font-size:14px;"
                        data-aos="fade-up"
                        data-aos-duration="3200"
                    >
                        "응원이 되는 글을 보내줘"
                    </p>

                    <p
                        class="request_font mb-0"
                        style="font-size:14px;"
                        data-aos="fade-up"
                        data-aos-duration="3200"
                    >
                        "동기 부여가 되는 글을 보내줘"
                    </p>

                    <p
                        class="request_font mb-0"
                        style="font-size:14px;"
                        data-aos="fade-up"
                        data-aos-duration="3200"
                    >
                        "여행 관련 글을 보내줘"
                    </p>
                </div>

                <v-btn
                    width="200"
                    class="d-flex mx-auto mt-6 rounded-10"
                    dark
                    large
                    depressed
                    color="#079aaa"
                    data-aos="fade-up"
                    data-aos-duration="2600"
                    @click="$router.push('/service/text')"
                >
                    오늘의 글 알림 시작하기
                </v-btn>
            </v-sheet>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        list: []
    }),

    mounted() {
        // 책 리뷰 불러오기
        this.load()
    },

    methods: {
        // 책 리뷰 불러오기
        load() {
            this.$http.post("/api/post/select", {
                params: {
                    type: 'review',
                }
            }).then(res => {
                console.log(res)
                this.list = res.data

                // 썸네일 추가
                this.matchThumbnail(this.list)
            })
        },

        // 썸네일 추가
        matchThumbnail(list){
            // 이미지
            const imgReg = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i

            list.forEach(e => {
                if (typeof e.content === 'string' && e.content.match(imgReg)) {
                    e.image = e.content.match(imgReg)[0].replace(/.*src="([^"]*)".*/, '$1');
                }
            })
        },

        // 책 리뷰 작성하기
        write() {
            if(this.$store.state.page_user.is_logined) {
                this.$router.push("/post/write?type=" + 'review')
            } else {
                this.$router.push("/auth/login")
            }
        },

        // 읽기로 이동
        read(item){
            if(!this.$store.state.page_user.is_logined){
                alert("게시글 읽기는 로그인 후 이용가능합니다.")
            }else{
                this.$router.push("/post/read?id=" + item.id + "&type=" + 'review')
            }
        },
    }
}
</script>