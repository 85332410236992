<template>
    <v-sheet
        style="margin:0 auto;"
        :width="$vuetify.breakpoint.mobile? '94%':'1000'"
    >
        <!-- 본문 -->
        <v-sheet
            :class="$vuetify.breakpoint.mobile? 'pa-2' : 'pa-4'"
            class="rounded-10 mt-8 mb-16"
            style="box-shadow: 0px 4px 16px #ddd;"
        >
            <!-- 상단 -->
            <v-sheet
                class="py-3 px-4 d-flex align-end"
            >
                <p
                    :style="$vuetify.breakpoint.mobile? 'width:200px;' : 'width:600px;'"
                    style="font-size:18px;"
                    class="ma-0 text-truncate"
                >
                    <!-- 제목 -->
                    <span class="font-weight-bold">
                        {{title}}
                    </span>
                </p>
                <v-spacer></v-spacer>

                <div
                    class="d-flex align-center"
                    style="margin-bottom:-10px;"
                >
                    <!-- 프로필 -->
                    <Profile
                        v-if="writer"
                        :user_id="writer"
                    />
                    <v-sheet
                        v-if="!$vuetify.breakpoint.mobile"
                        class="text-caption mr-1 py-1 mb-10px ml-2"
                    >
                        <!-- 조회수 -->
                        <font class="mr-4">
                            조회수 {{view_count}}
                        </font>

                        <!-- 작성일자 -->
                        <font style="color:#B3B3C2;">
                            {{new Date(created).toLocaleString()}}
                        </font>
                    </v-sheet>
                </div>
            </v-sheet>

            <!-- 내용 -->
            <v-divider class="mb-2 mx-2"></v-divider>
            <TipTapReader
                v-if="options.content"
                class="tiptap_style pa-4"
                :options="options"
            />
            <v-divider class="mb-2 mx-2"></v-divider>

            <!-- 좋아요, 댓글 수 -->
            <v-sheet class="mb-2 px-2 d-flex align-center">
                <!-- 좋아요 -->
                <v-btn
                    text
                    class="mr-2 px-1"
                    v-ripple="false"
                    @click="like()"
                >
                    <v-img class="mr-2" width="20" src="@/assets/board/like_default.png"></v-img>
                    <span style="font-size:12px;">
                        좋아요 {{like_list.length}}
                    </span>
                </v-btn>
                <v-spacer></v-spacer>

                <!-- 댓글 수 -->
                <p class="mb-0 mr-5 text-caption">
                    {{comment_list.length}}개의 댓글
                </p>
            </v-sheet>

            <!-- 댓글 -->
            <Comment
                :title="title"
                :writer="writer"
                :is_show="is_show"
                @updated="loadComment()"
            />
        </v-sheet>
    </v-sheet>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"
import Comment from "./read/comment"
import Profile from "./read/profile"

export default {
    components: {
        TipTapReader,
        Comment,
        Profile
    },

    data: () => ({
        title: "",
        created: "",
        writer: "",
        is_show: 0,
        nickname: "",
        view_count: 0,
        board_name: "",

        share_id: "",
        share_nickname: "",
        dialog: {
            share: false
        },

        is_scraped: false,

        //TipTap
        options: {
            content: "",
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        like_list: [],
        comment_list: [],
    }),

    watch: {
        dialog: {
            deep:true,
            handler(newData) {
                if(!newData.share){
                    this.share_id = ""
                }
            }
        }
    },

    mounted(){
        if(!this.$store.state.page_user.is_logined){
            alert("게시글 읽기는 로그인 후 이용가능합니다.")
        }

        // 게시글 정보 가져오기
        this.$http.post('/api/post/select/specific', {
            params: {
                id: this.$route.query.id
            }
        }).then((res) => {
            this.title = res.data[0].title
            this.options.content = res.data[0].content
            this.writer = res.data[0].user_id
            this.nickname = res.data[0].nickname
            this.view_count = res.data[0].view_count
            this.is_show = res.data[0].is_show
            this.created = res.data[0].created

            console.log(this.is_show)

            // this.componentKey++
        })

        // 댓글 가져오기 (for 댓글 수)
        this.loadComment()

        // 조회수 증가
        this.$http.post('/api/post/update/view_count', {
            params: {
                id: this.$route.query.id
            }
        })

        // 추천한 사람 리스트 가져오기
        this.loadPostLikeList()
    },

    methods: {
        // 댓글 가져오기 (for 댓글 수)
        loadComment(){
            this.$http.post('/api/post/comment/select', {
                params: {
                    post_id:this.$route.query.id
                }
            }).then((res) => {
                this.comment_list = res.data
            })
        },

        // 추천한 사람 리스트 가져오기
        loadPostLikeList(){
            this.$http.post('/api/post/select/like_list', {
                params: {
                    post_id: this.$route.query.id
                }
            }).then((res) => {
                this.like_list = res.data.map(a => a.user_id)
            })
        },

        // 추천
        like: _.debounce(function(){
            if(!this.$store.state.page_user.is_logined)
            {
                alert("게시글 추천은 로그인 이후 가능합니다.")
            }
            else if(this.$store.state.page_user.user_id == this.writer)
            {
                alert('자신의 글에 추천을 할 수 없습니다.')
            }
            else
            {
                if(this.like_list.includes(this.$store.state.page_user.user_id))
                {
                    alert("이미 추천한 게시글입니다.")
                }
                else
                {
                    this.$http.post('/api/post/insert/like', {
                        params: {
                            post_id: this.$route.query.id,
                            user_id: this.$store.state.page_user.user_id
                        }
                    }).then(() => {
                        this.loadPostLikeList()
                    })
                }
            }   
        }, 200),
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}

.v-btn::before {
    background-color: transparent;
}
</style>