<template>
    <div>
        <!-- PC -->
        <div v-if="!$vuetify.breakpoint.mobile" class="py-16 mb-16">
            <v-sheet
                class="py-16 px-8 mx-auto rounded-lg text-center"
                :width="$vuetify.breakpoint.mobile? '94%':480"
                outlined
            >
                <!-- 로고 & 문구 -->
                <div
                    class="d-flex mb-2 mx-auto"
                >
                    <v-img
                        height="120"
                        width="120"
                        contain
                        src="@/assets/logo/icon.svg"
                    ></v-img>
                </div>
                <p
                    class="mb-6 text-subtitle-1 font-weight-medium grey--text text--darken-2"
                >
                    마이페이지
                </p>

                <v-sheet
                    class="pt-0 pb-6 px-4 mt-10 rounded-xl"
                >
                    <table class="custom_table mx-auto px-2 mt-10">
                        <tr>
                            <td class="pb-8">아이디</td>
                            <td>
                                <v-text-field
                                    class="mx-4 mb-8 rounded-10"
                                    style="background:#f5f5f5;"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    :placeholder="$store.state.page_user.user_id"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="pb-8">닉네임</td>
                            <td>
                                <v-text-field
                                    class="mx-4 mb-8 rounded-10"
                                    outlined
                                    dense
                                    hide-details
                                    v-model="nickname"
                                    @change="$v.nickname.$touch()"
                                    @blur="$v.nickname.$touch()"
                                    :error-messages="nickname_Errors"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="pb-7">카카오톡 동의</td>
                            <td
                                class="pb-7"
                            >
                                <div
                                    class="d-flex justify-center"
                                >
                                    <v-switch
                                        style="margin-top:-4px;"
                                        hide-details
                                        dense
                                        :label="marketing_kakao? '동의':'거부'"
                                        v-model="marketing_kakao"
                                        @change="update_marketing_kakao()"
                                    ></v-switch>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="pb-7">현재 비밀번호<font class="ml-1 red--text">*</font></td>
                            <td>
                                <v-text-field
                                    class="mx-4 rounded-10"
                                    outlined
                                    dense
                                    persistent-placeholder
                                    type="password"
                                    v-model="password_prev"
                                    @change="$v.password_prev.$touch()"
                                    @blur="$v.password_prev.$touch()"
                                    :error-messages="password_prev_Errors"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="pb-7">새 비밀번호</td>
                            <td>
                                <v-text-field
                                    class="mx-4 rounded-10"
                                    outlined
                                    dense
                                    persistent-placeholder
                                    type="password"
                                    v-model="password_next"
                                    @change="$v.password_next.$touch()"
                                    @blur="$v.password_next.$touch()"
                                    :error-messages="password_next_Errors"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="pb-7">새 비밀번호 확인</td>
                            <td>
                                <v-text-field
                                    class="mx-4 rounded-10"
                                    outlined
                                    dense
                                    persistent-placeholder
                                    type="password"
                                    v-model="password_check"
                                    @change="$v.password_check.$touch()"
                                    @blur="$v.password_check.$touch()"
                                    :error-messages="password_check_Errors"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>

                    <!-- 제출 -->
                    <v-sheet class="mt-6 d-flex justify-center">
                        <v-btn
                            @click="submit()"
                            class="rounded-10 mx-2 px-10"
                            large
                            dark
                            depressed
                            color="primary"
                        >
                            비밀번호 수정하기
                        </v-btn>
                    </v-sheet>

                    <div
                        class="d-flex justify-center mt-6"
                    >
                        <v-btn
                            width="120"
                            class="grey--text text--darken-1 rounded-lg py-4 mr-2"
                            color="grey lighten-2"
                            depressed
                            small
                            dark
                            to="/auth/logout"
                        >
                            로그아웃
                        </v-btn>
                        <v-btn
                            width="120"
                            class="grey--text text--darken-1 rounded-lg py-4"
                            color="grey lighten-2"
                            depressed
                            small
                            @click="leaveSubmit()"
                        >
                            회원탈퇴하기
                        </v-btn>
                    </div>
                </v-sheet>
            </v-sheet>
        </div>

        <!-- Mobile -->
        <div v-if="$vuetify.breakpoint.mobile" class="py-6 mb-4">
            <v-sheet
                class="py-4 px-4 mx-auto rounded-lg text-center"
                :width="$vuetify.breakpoint.mobile? '94%':480"
                outlined
            >
                <!-- 로고 & 문구 -->
                <div
                    class="d-flex mx-auto"
                >
                    <v-img
                        height="60"
                        width="60"
                        contain
                        src="@/assets/logo/icon.svg"
                    ></v-img>
                </div>
                <p
                    class="mb-4 text-subtitle-1 font-weight-medium grey--text text--darken-2"
                >
                    마이페이지
                </p>

                <v-sheet
                    class="pt-0 pb-6 rounded-xl"
                >
                    <table class="custom_table mx-auto mt-10">
                        <tr>
                            <td class="pb-8">아이디</td>
                            <td>
                                <v-text-field
                                    class="ml-2 mb-8 rounded-10"
                                    style="background:#f5f5f5;"
                                    outlined
                                    dense
                                    readonly
                                    hide-details
                                    :placeholder="$store.state.page_user.user_id"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="pb-8">닉네임</td>
                            <td>
                                <v-text-field
                                    class="ml-2 mb-8 rounded-10"
                                    outlined
                                    dense
                                    hide-details
                                    v-model="nickname"
                                    @change="$v.nickname.$touch()"
                                    @blur="$v.nickname.$touch()"
                                    :error-messages="nickname_Errors"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="pb-7">카카오톡 동의</td>
                            <td
                                class="pb-7"
                            >
                                <div
                                    class="d-flex justify-center"
                                >
                                    <v-switch
                                        style="margin-top:-4px;"
                                        hide-details
                                        dense
                                        :label="marketing_kakao? '동의':'거부'"
                                        v-model="marketing_kakao"
                                        @change="update_marketing_kakao()"
                                    ></v-switch>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="pb-7">현재 비밀번호<font class="ml-1 red--text">*</font></td>
                            <td>
                                <v-text-field
                                    class="ml-2 rounded-10"
                                    outlined
                                    dense
                                    persistent-placeholder
                                    type="password"
                                    v-model="password_prev"
                                    @change="$v.password_prev.$touch()"
                                    @blur="$v.password_prev.$touch()"
                                    :error-messages="password_prev_Errors"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="pb-7">새 비밀번호</td>
                            <td>
                                <v-text-field
                                    class="ml-2 rounded-10"
                                    outlined
                                    dense
                                    persistent-placeholder
                                    type="password"
                                    v-model="password_next"
                                    @change="$v.password_next.$touch()"
                                    @blur="$v.password_next.$touch()"
                                    :error-messages="password_next_Errors"
                                ></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td class="pb-7">새 비밀번호 확인</td>
                            <td>
                                <v-text-field
                                    class="ml-2 rounded-10"
                                    outlined
                                    dense
                                    persistent-placeholder
                                    type="password"
                                    v-model="password_check"
                                    @change="$v.password_check.$touch()"
                                    @blur="$v.password_check.$touch()"
                                    :error-messages="password_check_Errors"
                                ></v-text-field>
                            </td>
                        </tr>
                    </table>

                    <!-- 제출 -->
                    <v-sheet class="mt-6 d-flex justify-center">
                        <v-btn
                            @click="submit()"
                            class="rounded-10 mx-2 px-10"
                            large
                            dark
                            depressed
                            color="primary"
                        >
                            비밀번호 수정하기
                        </v-btn>
                    </v-sheet>

                    <div
                        class="d-flex justify-center mt-6"
                    >
                        <v-btn
                            width="120"
                            class="grey--text text--darken-1 rounded-lg py-4 mr-2"
                            color="grey lighten-2"
                            depressed
                            small
                            dark
                            to="/auth/logout"
                        >
                            로그아웃
                        </v-btn>
                        <v-btn
                            width="120"
                            class="grey--text text--darken-1 rounded-lg py-4"
                            color="grey lighten-2"
                            depressed
                            small
                            @click="leaveSubmit()"
                        >
                            회원탈퇴하기
                        </v-btn>
                    </div>
                </v-sheet>
            </v-sheet>
        </div>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],

    validations: {
        nickname: {
            required,
            minLength: minLength(2)
        },

        password_prev: {
            required,
            minLength: minLength(8)
        },

        password_next: {
            required,
            minLength: minLength(8)
        },

        password_check: {
            required,
            sameAsPassword: sameAs('password_next')
        }
    },

    computed: {
        // 닉네임 Error 문구
        nickname_Errors () {
            const errors = []
            if (!this.$v.nickname.$dirty) return errors
            !this.$v.nickname.required && errors.push('닉네임을 입력해주세요')
            !this.$v.nickname.minLength && errors.push('닉네임은 최소 2자 이상이여야합니다')
            this.nickname_list.includes(this.$v.nickname.$model) && errors.push('입력하신 닉네임은 이미 존재합니다.')
            return errors
        },

        // 현재 비밀번호 Error 문구
        password_prev_Errors () {
            const errors = []
            if (!this.$v.password_prev.$dirty) return errors
            !this.$v.password_prev.required && errors.push('현재 비밀번호를 입력해주세요')
            !this.$v.password_prev.minLength && errors.push('비밀번호는 최소 8자 이상이여야합니다')
            return errors
        },

        // 새 비밀번호 Error 문구
        password_next_Errors () {
            const errors = []
            if (!this.$v.password_next.$dirty) return errors
            !this.$v.password_next.required && errors.push('새 비밀번호를 입력해주세요')
            !this.$v.password_next.minLength && errors.push('비밀번호는 최소 8자 이상이여야합니다')
            return errors
        },

        // 새 비밀번호 확인 Error 문구
        password_check_Errors () {
            const errors = []
            if (!this.$v.password_check.$dirty) return errors
            !this.$v.password_check.required && errors.push('새 비밀번호 확인을 입력해주세요')
            !this.$v.password_check.sameAsPassword && errors.push('비밀번호 확인이 같지 않습니다')
            return errors
        },
    },

    data: () => ({
        nickname: '',
        password_prev: "",
        password_next: "",
        password_check: "",
        marketing_kakao: false,

        nickname_list: [],
    }),

    created () {
        // 기존 유저정보 검색
        this.loadUserList()
    },

    mounted(){
        this.nickname = this.$store.state.page_user.nickname
    },

    methods: {
        // 기존 유저정보 검색
        async loadUserList(){
            await this.$http.post('/api/user/select')
            .then((res) => {
                if(res.data.length){
                    this.nickname_list = res.data
                        .filter(e => e.user_id !== this.$store.state.page_user.user_id)
                        .map(e => e.nickname)
                }
            })
        },

        // 카카오톡 동의 수정하기
        update_marketing_kakao(){
            alert(`카카오톡 마케팅 수신 동의가 ${this.marketing_kakao? "동의":"거부"}처리되었습니다`)
        },

        // 수정하기
        submit: _.debounce(async function() {
            // 입력값 기본 검증
            this.$v.$touch()

            // 기존 유저정보 검색
            await this.loadUserList()

            // 입력값 기본 검증 통과 시
            if(!this.$v.$invalid){
                // 닉네임 재검증
                if(this.nickname_list.includes(this.nickname))
                {
                    alert('입력하신 닉네임은 이미 존재합니다.')
                    return
                }

                this.$http.post("/api/user/select/login", {
                    params: {
                        user_id: this.$store.state.page_user.user_id,
                        password:this.password_prev
                    }
                }).then((res) => {
                    if(!res.data.length){
                        // 임시 비밀번호 확인
                        this.$http.post("/api/simple/temp_password/select/check", {
                            params: {
                                user_id: this.$store.state.page_user.user_id,
                                password: this.password_prev
                            }
                        }).then((res) => {
                            if(!res.data.length){
                                alert("현재 비밀번호가 일치하지 않습니다.")
                            }else{
                                this.$http.post("/api/user/update", {
                                    params: {
                                        user_id: this.$store.state.page_user.user_id,
                                        nickname: this.nickname,
                                        password_next: this.password_next
                                    }
                                }).then((res) => {
                                    if(res.data.affectedRows){
                                        alert("회원정보가 수정되었습니다.")
                                        // this.password_prev = ""
                                        // this.password_next = ""
                                        // this.password_check = ""
                                        // this.load()
                                        this.$router.push('/')
                                    }
                                })
                            }
                        })
                    }else{
                        this.$http.post("/api/user/update", {
                            params: {
                                user_id: this.$store.state.page_user.user_id,
                                nickname: this.nickname,
                                password_next: this.password_next
                            }
                        }).then((res) => {
                            if(res.data.affectedRows){
                                alert("회원정보가 수정되었습니다.")
                                // this.password_prev = ""
                                // this.password_next = ""
                                // this.password_check = ""
                                // this.load()
                                this.$router.push('/')
                            }
                        })
                    }
                })
            }
        }, 500),

        // 회원탈퇴하기
        leaveSubmit(){
            if(confirm("정말 회원탈퇴를 하시겠습니까?")){
                this.$http.post("/api/user/delete", {
                    params: {
                        user_id: this.$store.state.page_user.user_id
                    }
                }).then((res) => {
                    if(res.data.affectedRows){
                        alert("회원탈퇴가 완료되었습니다.")
                        this.$router.push('/auth/logout')
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
/* 리스트 기본 이펙트 제거 */
::v-deep .v-list-item:before,
::v-deep .v-list-item:hover:before,
::v-deep .v-list-item--active:before,
::v-deep .v-list-item--active:hover:before,
::v-deep .v-list-item:focus:before {
    opacity: 0;
}
</style>
<style scoped>
/* 검색 keyword */
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

/* 검색 type */
::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}

/* 입력란 공통 (fieldset) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 공통 (label) */
.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}

/* Custom Truncate */
.custom-truncate-mobile{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.custom_table tr td{
    padding:0px;
}

.custom_table tr td:first-child{
    width:100px;
    font-size:14px;
    font-weight:bold;
}

/* v-checkbox > 라벨 */
::v-deep .v-input--switch .v-label {
    font-size: 14px !important;
    margin-bottom: 4px;
}  

/* v-text-field, v-autocomplete > 입력값 글자 */
::v-deep .v-text-field input {
    color: #626262 !important;
    font-size:14px;
}
</style>